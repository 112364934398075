import PropTypes from 'prop-types';

/* STYLE */
import BgAnimation from './BgAnimation';

BgAnimation.propTypes = {
    theme: PropTypes.object,
    type: PropTypes.oneOf(['page', 'element']),
    error: PropTypes.bool,
    timedOut: PropTypes.bool,
};

BgAnimation.defaultProps = {
    is: 'div',
    type: 'element',
};

export default BgAnimation;

import Fetcher from '@docomodigital/js-fetcher';

export default new Fetcher({
    baseURL: '',
    options: {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        credentials: 'include',
    },
});

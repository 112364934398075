import React from 'react';
import {
    fixedPropTypes,
    floatPropTypes,
    linearGradientPropTypes,
    swipePropTypes,
} from 'props-to-styled';
import PropTypes from 'prop-types';

/* STYLE */
import BlockStyle from './BlockStyle';

const Block = React.forwardRef((props, ref) => (
    <BlockStyle {...props} ref={ref}>
        {props.children}
    </BlockStyle>
));

Block.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
    ...fixedPropTypes,
    ...floatPropTypes,
    ...linearGradientPropTypes,
    ...swipePropTypes,
};

Block.defaultProps = {
    is: 'div',
};

export default Block;

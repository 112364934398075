import Config from '@docomodigital/js-config';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Container from 'components/Container/Container.jsx';
import Icon from 'components/Icon/Icon.jsx';
import Image from 'components/Image/Image.jsx';

const Banner = props => (
    <Block linearGradient={props.linearGradient || []}>
        <Container maxWidth={0}>
            <Link to={Config.get('ROUTING.ROUTER_HOME.path')}>
                <Icon
                    content="back"
                    color={props.backArrowColor}
                    fontSize={2}
                    position="relative"
                    pt="10px"
                    pb="0px"
                    pl="10px"
                    zIndex="3"
                />
            </Link>
            <Image
                src={props.src}
                alt={props.alt}
                maxHeight={[200, 320]}
                m="auto"
                zIndex="2"
                mt="-32px"
            />
        </Container>
    </Block>
);

Banner.propTypes = {
    theme: PropTypes.object,
    backArrowColor: PropTypes.string.isRequired,
    linearGradient: PropTypes.arrayOf(PropTypes.string),
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

Banner.defaultProps = {
    is: 'div',
};

export default Banner;

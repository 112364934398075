export default compileData => ({
    top_carousel: compileData.top_carousel || [],
    video: compileData.video || [],
    games: [
        ...(compileData.androidapplications
            ? compileData.androidapplications
            : []),
        ...(compileData.html5applications ? compileData.html5applications : []),
    ],
});

import styled from 'styled-components/macro';
import {
    width,
    verticalAlign,
    boxShadow,
    display,
    borders,
    borderRadius,
    fontFamily,
    space,
    color,
    height,
    lineHeight,
    fontSize,
    textAlign,
    position,
} from 'styled-system';

import { ellipsis, uppercase } from 'props-to-styled';

/* COMPONENTS */
import Base from 'components/Base';

export default styled(Base).attrs(props => ({
    fontFamily: 'text',
    color: props.color || 'primary',
}))`
    /* styled-system */
    background-clip: padding-box;
    ${width};
    ${verticalAlign};
    ${boxShadow};
    ${display};
    ${borders};
    ${borderRadius};
    ${fontFamily};
    ${space};
    ${color};
    ${height};
    ${lineHeight};
    ${fontSize};
    ${textAlign};
    ${position};

    /* custom styles */
    ${ellipsis};
    ${uppercase};
`;

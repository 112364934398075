import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import icomoonEOT from './icomoon-ubuntu.eot';
import icomoonSVG from './icomoon-ubuntu.svg';
import icomoonTTF from './icomoon-ubuntu.ttf';
import icomoonWOFF from './icomoon-ubuntu.woff';

export default createGlobalStyle`
    @font-face {
        font-family:'icomoon-ubuntu';
        font-display: auto;
        src: url(${staticPrefix + icomoonWOFF}) format('woff'),
            url(${staticPrefix + icomoonTTF}) format('truetype'),   
            url(${staticPrefix + icomoonEOT}),
            url(${staticPrefix +
                icomoonEOT}) format('embedded-opentype'),        
            url(${staticPrefix + icomoonSVG}) format('svg');
    }

    .icon-icon-search:before {
        content: "\\e901";
      }
      .icon-icon-account:before {
        content: "\\e902";
      }
      .icon-arrow_down:before {
        content: "\\e905";
      }
      .icon-arrow_sx:before {
        content: "\\e904";
      }
      .icon-arrow_dx:before {
        content: "\\e906";
      }
      .icon-close:before {
        content: "\\e903";
      }
      .icon-back:before {
        content: "\\e900";
      }
      .icon-show-pw:before {
        content: "\\e920";
        color: #555;
      }
      .icon-hide-pw:before {
        content: "\\e921";
        color: #555;
      }
      .icon-allert:before {
        content: "\\e922";
        color: #555;
      }
      .icon-check:before {
        content: "\\e923";
        color: #555;
      }
      .icon-edit:before {
        content: "\\e924";
        color: #555;
      }
      .icon-user:before {
        content: "\\e925";
        color: #555;
      }
      .icon-email:before {
        content: "\\e926";
        color: #555;
      }
      .icon-suggestion:before {
        content: "\\e927";
        color: #555;
      }
      .icon-status-sub:before {
        content: "\\e928";
        color: #555;
      }
      .icon-social-logged:before {
        content: "\\e929";
        color: #555;
      }
      .icon-sms:before {
        content: "\\e92a";
        color: #555;
      }
      .icon-status-unsub:before {
        content: "\\e92b";
        color: #555;
      }
      .icon-trash:before {
        content: "\\e92d";
      }
      .icon-arrow_down1:before {
        content: "\\e907";
      }
    
    

`;

import React from 'react';
import ImageStyle from './ImageStyle';
import { FadeIn } from 'animate-css-styled-components';
import PropTypes from 'prop-types';
import { imagePropTypes } from './Image';

export const InternalImage = props => {
    const { wrapper, ...rest } = props;
    let Wrapper;
    if (wrapper !== false) {
        Wrapper = wrapper || <FadeIn />;
    }

    return Wrapper ? (
        React.cloneElement(Wrapper, null, React.createElement(ImageStyle, rest))
    ) : (
        <ImageStyle {...rest} />
    );
};

InternalImage.propTypes = {
    ...imagePropTypes,
    wrapper: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
};

import React, { PureComponent } from 'react';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

const createMarkup = __html => ({ __html });

class Terms extends PureComponent {
    render() {
        return (
            <Block
                dangerouslySetInnerHTML={createMarkup(
                    this.props.compileData[0].content
                )}
                maxWidth="1240px"
                p="20px"
                mt="0px"
                mr="auto"
                mb="0px"
                ml="auto"
            ></Block>
        );
    }
}

export default Terms;

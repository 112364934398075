import { filter, float, shape } from 'props-to-styled';
import styled from 'styled-components/macro';
import {
    zIndex,
    space,
    borders,
    borderColor,
    borderRadius,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    position,
    top,
    right,
    bottom,
    left,
    display,
} from 'styled-system';
import filterAttribute from 'styles/attributes/filter';

/* COMPONENTS */
import Base from 'components/Base';

export default styled(Base).attrs(props => ({
    filter: filterAttribute(props),
}))`
    /* styled-system */
    ${zIndex};
    ${space};
    ${borders};
    ${borderColor};
    ${borderRadius};
    ${width};
    ${minWidth};
    ${maxWidth};
    ${height};
    ${minHeight};
    ${maxHeight};
    ${position};
    ${top};
    ${right};
    ${bottom};
    ${left};
    ${display};

    /* custom styles */
    ${filter};
    ${float};
    ${shape};

    ${props => (props.pointerFix ? 'pointer-events: none;' : null)};
`;

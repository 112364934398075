import { combineReducers } from 'redux';
import { SET_CONNECTION, SET_CONTAINERS } from './actions';

/*
    containers: [...],
    isSmartBannerShown: true|false
*/

const containers = (state = [], action) => {
    switch (action.type) {
        case SET_CONTAINERS:
            return action.value;
        default:
            return state;
    }
};

const connection = (state = { wifi: false, expiresAt: null }, action) => {
    switch (action.type) {
        case SET_CONNECTION:
            return Object.assign({}, state, {
                wifi: action.value,
                expiresAt: Math.floor(Date.now() / 1000) + 60,
            });
        default:
            return state;
    }
};

export default combineReducers({ containers, connection });

import styled from 'styled-components/macro';

import {
    color,
    position,
    lineHeight,
    verticalAlign,
    fontSize,
    display,
    zIndex,
} from 'styled-system';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

export default styled(Block).attrs(props => ({
    fontFamily: 'icon',
}))`
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /*line-height: 1; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${color};
    ${display};
    ${fontSize};
    ${lineHeight};
    ${position};
    ${verticalAlign};
    ${zIndex};
`;

import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import vodafoneltEOT from './vodafonelt-regular-webfont.eot';
import vodafoneltSVG from './vodafonelt-regular-webfont.svg';
import vodafoneltTTF from './vodafonelt-regular-webfont.ttf';
import vodafoneltWOFF from './vodafonelt-regular-webfont.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'vodafone_ltregular';
        font-display: auto;
        src: url(${staticPrefix + vodafoneltWOFF}) format('woff'),
            url(${staticPrefix + vodafoneltTTF}) format('truetype'),   
            url(${staticPrefix + vodafoneltTTF}),
            url(${staticPrefix +
                vodafoneltEOT}) format('embedded-opentype'),        
            url(${staticPrefix + vodafoneltSVG}) format('svg');
            font-weight: normal;
            font-style: normal;
    }
`;

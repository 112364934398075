import Config from '@docomodigital/js-config';
import Analytics from '@docomodigital/js-analytics';
import Dictionary from '@docomodigital/js-dictionary';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NewtonAdapter from 'newton-adapter';
import { Helmet } from 'react-helmet';
import styled, { withTheme } from 'styled-components/macro';

/* COMPONENTS */
import Badge from 'components/Badge/Badge.jsx';
import Block from 'components/Block/Block.jsx';
import Card from 'components/Card/Card.jsx';
import Carousel from 'components/Carousel/Carousel.jsx';
import Container from 'components/Container/Container.jsx';
import Cover from 'components/Cover/Cover.jsx';
import List from 'components/List/List.jsx';
import SwipeList from 'components/SwipeList/SwipeList.jsx';
import Text from 'components/Text/Text.jsx';
import BgAnimation from 'components/BgAnimation/BgAnimation.jsx';

/* LIBRARIES */
import apiByType from 'lib/formatter/apiByType';

const BadgeLink = styled(({ first, last, ...props }) => <Link {...props} />)`
    ${props => props.first && `margin-left: auto`};
    ${props => props.last && `margin-right: auto`};
`;

class Home extends PureComponent {
    constructor(props) {
        super(props);
        this.rotImages = [
            Config.get('DDCORNER_FINAL_02'),
            Config.get('DDCORNER_FINAL_03'),
            Config.get('DDCORNER_FINAL_04'),
            Config.get('DDCORNER_FINAL_05'),
            Config.get('DDCORNER_FINAL_06'),
            Config.get('DDCORNER_FINAL_07'),
            Config.get('DDCORNER_FINAL_08'),
            Config.get('DDCORNER_FINAL_09'),
        ];
        this.rotColors = [
            '#018b64',
            '#7bba4d',
            '#2690ce',
            '#ffc95e',
            '#9fcc6d',
            '#dea027',
            '#f17381',
            '#59c2ed',
        ];
        this.random = Math.floor(Math.random() * this.rotImages.length);
        this.carouselSettings = {
            arrows: true,
            autoplay: false,
            dots: true,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: this.props.theme.breakpoints[0],
                    settings: { slidesToShow: 1, arrows: false },
                },
            ],
            // slidesToShow: 1
        };
        this.imageSizes = Config.get('IMG_SIZES');
        this.trackBadgeClick = this.trackBadgeClick.bind(this);
    }

    trackBadgeClick(containerName) {
        const eventName = 'Badge_click';
        Analytics.trackEvent({
            category: 'HomePage',
            action: eventName,
            label: containerName,
        });
        NewtonAdapter.trackEvent({
            name: eventName,
            properties: {
                brand_ID: containerName,
            },
        });
    }

    render() {
        const badges = this.props.containers.map((item, index) => (
            <BadgeLink
                first={index === 0}
                last={index === this.props.containers.length - 1}
                title={item.name}
                to={item.url_webapp}
                key={index}
                onClick={() => this.trackBadgeClick(item.container)}
                data-mipqa="home-badge-item"
                data-mipqa-type={item.type}
            >
                <Badge
                    src={item.images.badge}
                    alt={item.name}
                    height={[0, 1, 8]}
                    minWidth={[0, 1, 8]}
                    mt={10}
                    ml={[0, 10, 10]}
                    mr={[0, 10, 10]}
                    border={5}
                    filter="drop-shadow(0px 1px 3px rgba(0,0,0,0.3))"
                />
            </BadgeLink>
        ));

        const cleanCompileData = apiByType(this.props.compileData[0]);

        const carouselItems = cleanCompileData.top_carousel.map(
            (item, index) => (
                <Cover
                    key={index}
                    id={item.id}
                    link={item.url_zoom}
                    title={item.title}
                    imageProps={{
                        src: item.images.cover.ratio_1_4,
                        ratio: 'video',
                        width: this.imageSizes.cover.large,
                        alt: item.title,
                        pointerFix: true,
                    }}
                    container={item.container}
                    contentType={item.format}
                    fromPage="Home"
                    data-mipqa="home-carousel-item"
                />
            )
        );

        const videoItems = cleanCompileData.video.map((item, index) => (
            <Card
                key={index}
                id={item.id}
                link={item.url_zoom}
                title={item.title}
                imageProps={{
                    src: item.images.cover.ratio_1_4,
                    ratio: 'video',
                    width: this.imageSizes.cover.medium,
                    alt: item.title,
                }}
                container={item.container}
                contentType={item.format}
                borderRadius={[1]}
                p={1}
                fromPage="Home"
                eventName="Content_click"
                data-mipqa="home-video-selection-item"
            />
        ));

        const gamesItems = cleanCompileData.games.map((item, index) => (
            <Card
                key={index}
                id={item.id}
                link={item.url_zoom}
                title={item.title}
                imageProps={{
                    src:
                        item.images.icon && item.images.icon.ratio_1
                            ? item.images.icon.ratio_1
                            : item.images.cover.ratio_1,
                    ratio: 'games',
                    width: this.imageSizes.cover.medium,
                    alt: item.title,
                }}
                container={item.container}
                contentType={item.format}
                borderRadius={[1]}
                p={1}
                fromPage="Home"
                eventName="Content_click"
                data-mipqa="home-games-selection-item"
            />
        ));

        return [
            <Helmet key="helmet">
                <title>
                    {Dictionary.get('SEO_WEBAPP_HP_TITLE')}{' '}
                    {Dictionary.get('SEO_WEBAPP_TITLE_SERVICE')}
                </title>
                <meta
                    name="description"
                    content={Dictionary.get('SEO_WEBAPP_HP_DESCRIPTION')}
                />
            </Helmet>,
            <React.Fragment key="template">
                <Block position="relative" overflow="inherit">
                    <BgAnimation
                        backgroundImage={`url(${this.rotImages[this.random]})`}
                    ></BgAnimation>
                    <Container position="absolute" top="0px" width="100%">
                        <SwipeList pb={1}>{badges}</SwipeList>
                    </Container>
                </Block>

                {/* CAROUSEL */}

                {carouselItems.length ? (
                    <Block
                        bg={this.rotColors[this.random]}
                        pl={10}
                        pb={5}
                        pr={10}
                        boxShadow={1}
                    >
                        <Carousel
                            title={Dictionary.get('WEBAPP_DDC_TOP_CAROUSEL')}
                            settings={this.carouselSettings}
                            maxWidth={[6, 5, 0]}
                            data-mipqa="home-carousel"
                        >
                            {carouselItems}
                        </Carousel>
                    </Block>
                ) : null}

                {/* VIDEO SELECTION */}

                {videoItems.length ? (
                    <Block bg="bgBox1" p={[0, 1, 2]} boxShadow={1}>
                        <Text
                            is="h2"
                            fontSize={[3, 3]}
                            mt={2}
                            mb={1}
                            textAlign="center"
                            uppercase
                            ellipsis
                        >
                            <Text
                                is="span"
                                display="inline-block"
                                bg="transparent"
                                borderRadius={2}
                                height={6}
                                color="secondaryFontTitleHome"
                                pr={12}
                            >
                                {Dictionary.get('WEBAPP_DDC_TITLE1_PART1')}
                            </Text>
                            <Text
                                is="span"
                                display="inline-block"
                                bg="transparent"
                                borderRadius={2}
                                height={6}
                            >
                                {Dictionary.get('WEBAPP_DDC_TITLE1_PART2')}
                            </Text>
                        </Text>
                        <Container maxWidth={0}>
                            <List columns={[2, 3, 4, 4]}>{videoItems}</List>
                        </Container>
                    </Block>
                ) : null}

                {/* GAMES SELECTION */}

                {gamesItems.length ? (
                    <Block bg="bgBox2" p={[0, 1, 2]}>
                        <Text
                            is="h2"
                            fontSize={[3, 3]}
                            mt={2}
                            mb={1}
                            textAlign="center"
                            uppercase
                            ellipsis
                        >
                            <Text
                                is="span"
                                display="inline-block"
                                bg="transparent"
                                borderRadius={2}
                                height={6}
                                pr={12}
                            >
                                {Dictionary.get('WEBAPP_DDC_TITLE2_PART1')}
                            </Text>
                            <Text
                                is="span"
                                display="inline-block"
                                bg="transparent"
                                borderRadius={2}
                                height={6}
                                color="secondaryFontTitleHome"
                            >
                                {Dictionary.get('WEBAPP_DDC_TITLE2_PART2')}
                            </Text>
                        </Text>
                        <Container maxWidth={0}>
                            <List columns={[2, 3, 4, 4]}>{gamesItems}</List>
                        </Container>
                    </Block>
                ) : null}
            </React.Fragment>,
        ];
    }
}

const mapStateToProps = state => ({
    containers: state.containers,
});

export default connect(mapStateToProps)(withTheme(Home));

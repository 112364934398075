import styled from 'styled-components/macro';
import { themeGet } from 'styled-system';

import Block from 'components/Block/Block.jsx';

export const PromotionalStyle = styled(Block).attrs(props => ({
    bg: props.bg || 'bgPromotional',
    height: props.height || 5,
    zIndex: props.zIndex || 100,
}))``;

export default styled(Block).attrs(props => ({
    fixed: props.fixed || themeGet('navbarFixed')(props),
    // borderBottom: props.borderBottom || 0,
    // borderColor: props.borderColor || 'secondary',
    boxShadow: props.boxShadow || 1,
    bg: props.bg || 'bgHeader',
    color: props.color || 'primary',
    height: props.height || 5,
    zIndex: props.zIndex || 100,
    position: props.position || 'relative',
}))``;

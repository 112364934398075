import { createStore } from 'redux';
import reducer from './reducer';

export default createStore(
    reducer,
    {
        containers: [],
        connection: { wifi: false, expiresAt: null },
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

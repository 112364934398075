import React from 'react';
import { floatPropTypes, shapePropTypes } from 'props-to-styled';
import PropTypes from 'prop-types';
import imageSize from 'lib/formatter/imageSize';
import LazyImage from './LazyImage';
import { InternalImage } from './InternalImage';

export const imagePropTypes = {
    theme: PropTypes.object,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    lazy: PropTypes.bool,
    wrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    onLoad: PropTypes.func,

    ratio: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    method: PropTypes.string,
    pointerFix: PropTypes.bool,
    ...floatPropTypes,
    ...shapePropTypes,
};

const canLazyLoad = () =>
    window &&
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype;

class Image extends React.Component {
    render() {
        const {
            lazy,
            ratio,
            width,
            method,
            src: sourceSrc,
            ...rest
        } = this.props;

        let src = sourceSrc;
        if (ratio) {
            src = imageSize(src, ratio, width, method);
        }

        if (!lazy || !canLazyLoad()) {
            return <InternalImage src={src} width={width} {...rest} />;
        }

        return <LazyImage src={src} ratio={ratio} width={width} {...rest} />;
    }
}

Image.propTypes = imagePropTypes;

Image.defaultProps = {
    is: 'img',
    lazy: false,
};

Image.displayName = 'Image';

export default Image;

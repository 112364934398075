import Config from '@docomodigital/js-config';
import User from '@productfe/react-user';

/* PARSE API PARAMS 
    - apiParams: params of an API
        {
            "real_customer_id": "#container",
            "vh": "#container",
            "white_label": "#container",
            "sort": "-born_date",
            "offset": "",
            "getBy": "category",
            "category": "",
            "size": "10",
            "fw": "ddk"
        }

    - routeParams, usually props.match.params
*/

export default (apiParams = [], routeParams = {}) => {
    const params = {};

    Object.keys(apiParams).forEach(key => {
        const value = apiParams[key].toString();

        /* 
            get route param with same name
                apiParam: '@'
            ( get routeParams.apiParam )
        */
        if (value === '@') {
            params[key] = routeParams[key];

            /* 
            get route param with different name
                apiParam: '@container'
            ( get routeParams.container )
        */
        } else if (value.indexOf('@') !== -1) {
            params[key] = routeParams[value.substr(1)];

            /* 
            get from map params
                apiParam: '#container'
            ( get MAP_PARAMS[apiParam][routeParams.container] )
        */
        } else if (value.indexOf('#') !== -1) {
            try {
                const mapKey = routeParams[value.substr(1)];
                params[key] = Config.get('MAP_PARAMS')[key][mapKey];
            } catch (e) {
                params[key] = '';
            }

            /* 
            get access token
                apiParam: 'access_token'
            ( get access token )
        */
        } else if (key === 'access_token') {
            params[key] = User.getUserToken();

            /* 
            get page ( page param start with 1, not 0  :| )
                page: ''
            ( get 1 )
        */
        } else if (key === 'page' && value === '') {
            params[key] = 1;

            /* 
            get per_page
                per_page: '10'
            ( get integer 10 )
        */
        } else if (key === 'per_page' && value !== '') {
            params[key] = parseInt(value, 10);

            /* 
            get explicit value
                explicit: 'value'
            ( get 'value' )
        */
        } else if (value !== '') {
            params[key] = value;
        }
    });

    return params;
};

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Config from '@docomodigital/js-config';
import Dictionary from '@docomodigital/js-dictionary';

import Block from 'components/Block/Block.jsx';
import Text from 'components/Text/Text.jsx';

class Unsubscribed extends PureComponent {
    render() {
        return (
            <Block
                mt="20px"
                mr="auto"
                mb="0px"
                ml="auto"
                maxWidth="600px"
                overflow="hidden"
            >
                <Text textAlign="center" p="10px">
                    {Dictionary.get('WEBAPP_STATUS_UNSUBSCRIBE_TEXT1')}
                </Text>
                <Text textAlign="center">
                    {Dictionary.get('WEBAPP_STATUS_UNSUBSCRIBE_TEXT2')}
                </Text>
                <Link
                    className="btn btn-secondary margin-top"
                    to={Config.get('ROUTING.ROUTER_HOME.path')}
                >
                    {Dictionary.get('WEBAPP_NEXT')}
                </Link>
            </Block>
        );
    }
}

export default Unsubscribed;

import Config from '@docomodigital/js-config';
import Raven from 'raven-js';
import React, { Component } from 'react';
import Storage from '@docomodigital/js-storage';

/* COMPONENTS */
import Button from 'components/Button/Button.jsx';

/* CONSTANTS */
const newtonEnvKey = '_force_newton_env';
const newtonEnvValue = 'development';
const qaKey = 'dadaqa_test';
const qaValue = 'gotoQA';
const logKey = 'enable-logger';
const logValue = true;
const countryKey = '_forcecountry';
const fromCookie = { type: 'cookie', path: '/' };
const fromLocalStorage = { type: 'localstorage' };

/* CUSTOM COMPONENT */
const DevButton = props => (
    <Button
        minWidth="20px"
        width="100%"
        p="1px"
        textAlign="center"
        onClick={props.onClick}
    >
        {props.children}
    </Button>
);
const DevTd = props => {
    const style = Object.assign(
        { textAlign: 'center' },
        props.enabled
            ? {
                  backgroundColor: 'green',
                  color: 'white',
              }
            : {
                  backgroundColor: 'red',
                  color: 'black',
              }
    );

    return (
        <td colSpan={props.colSpan || '1'} style={style}>
            {props.children}
        </td>
    );
};

class Developer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newtonEnv: false,
            qaEnabled: false,
            logEnabled: false,
            country: false,
            countryNew: '',
            gotoURL: '',
        };
        this.reload = this.reload.bind(this);
        this.readFlags = this.readFlags.bind(this);
        this.toggleNewtonEnv = this.toggleNewtonEnv.bind(this);
        this.toggleQaEnabled = this.toggleQaEnabled.bind(this);
        this.toggleLogEnabled = this.toggleLogEnabled.bind(this);
        this.handleCountry = this.handleCountry.bind(this);
        this.handleGotoURL = this.handleGotoURL.bind(this);
        this.setCountry = this.setCountry.bind(this);
        this.deleteCountry = this.deleteCountry.bind(this);
        this.gotoNewURL = this.gotoNewURL.bind(this);
        this.simulateErrorOne = this.simulateErrorOne.bind(this);
        this.simulateErrorTwo = this.simulateErrorTwo.bind(this);
        this.simulateErrorThree = this.simulateErrorThree.bind(this);
        this.simulateErrorFour = this.simulateErrorFour.bind(this);
    }

    componentDidMount() {
        this.readFlags();
    }

    handleCountry(event) {
        this.setState({ countryNew: event.target.value });
    }

    handleGotoURL(event) {
        this.setState({ gotoURL: event.target.value });
    }

    readFlags() {
        this.setState({
            newtonEnv: Storage.get(newtonEnvKey, fromCookie) === newtonEnvValue,
            qaEnabled: Storage.get(qaKey, fromCookie),
            logEnabled: Storage.get(logKey, fromLocalStorage),
            country: Storage.get(countryKey, fromCookie),
            countryNew: Storage.get(countryKey, fromCookie)
                ? Storage.get(countryKey, fromCookie)
                : '',
        });
    }

    toggleNewtonEnv() {
        if (this.state.newtonEnv) {
            Storage.delete(newtonEnvKey, fromCookie);
        } else {
            Storage.set(newtonEnvKey, newtonEnvValue, fromCookie);
        }
        this.readFlags();
    }

    toggleQaEnabled() {
        if (this.state.qaEnabled) {
            Storage.delete(qaKey, fromCookie);
        } else {
            Storage.set(qaKey, qaValue, fromCookie);
        }
        this.readFlags();
    }

    toggleLogEnabled() {
        if (this.state.logEnabled) {
            Storage.delete(logKey, fromLocalStorage);
        } else {
            Storage.set(logKey, logValue, fromLocalStorage);
        }
        this.readFlags();
    }

    setCountry() {
        Storage.set(countryKey, this.state.countryNew, fromCookie);
        this.readFlags();
    }

    deleteCountry() {
        Storage.delete(countryKey, fromCookie);
        this.readFlags();
    }

    gotoNewURL() {
        // window.history.push(this.state.gotoURL, true);
        this.props.history.push(this.state.gotoURL);
    }

    simulateErrorOne() {
        if (Config.get('SENTRY_ENABLE')) {
            Raven.captureException(new Error('Test Error 1/4'));
        } else {
            console.warn('Sentry disabled');
        }
    }

    simulateErrorTwo() {
        if (Config.get('SENTRY_ENABLE')) {
            Raven.captureMessage('Test Error 2/4');
        } else {
            console.warn('Sentry disabled');
        }
    }

    simulateErrorThree() {
        if (Config.get('SENTRY_ENABLE')) {
            try {
                throw new Error('Test Error 3/4');
            } catch (error) {
                Raven.captureException(error);
            }
        } else {
            console.warn('Sentry disabled');
        }
    }

    simulateErrorFour() {
        if (Config.get('SENTRY_ENABLE')) {
            throw new Error('Test Error 4/4');
        } else {
            console.warn('Sentry disabled');
        }
    }

    reload() {
        window.location.href = '/';
    }

    render() {
        return (
            <React.Fragment>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ width: '60%' }}></td>
                            <td style={{ width: '10%' }}></td>
                            <td style={{ width: '10%' }}></td>
                            <td style={{ width: '10%' }}></td>
                            <td style={{ width: '10%' }}></td>
                        </tr>
                        <tr>
                            <th>Newton Dev Env (static2):</th>
                            <DevTd colSpan="2" enabled={this.state.newtonEnv}>
                                {this.state.newtonEnv ? 'ON' : 'OFF'}
                            </DevTd>
                            <td colSpan="2">
                                <DevButton onClick={this.toggleNewtonEnv}>
                                    TOGGLE
                                </DevButton>
                            </td>
                        </tr>
                        <tr>
                            <th>QA</th>
                            <DevTd colSpan="2" enabled={this.state.qaEnabled}>
                                {this.state.qaEnabled ? 'ON' : 'OFF'}
                            </DevTd>
                            <td colSpan="2">
                                <DevButton onClick={this.toggleQaEnabled}>
                                    TOGGLE
                                </DevButton>
                            </td>
                        </tr>
                        <tr>
                            <th>LOG</th>
                            <DevTd colSpan="2" enabled={this.state.logEnabled}>
                                {this.state.logEnabled ? 'ON' : 'OFF'}
                            </DevTd>
                            <td colSpan="2">
                                <DevButton onClick={this.toggleLogEnabled}>
                                    TOGGLE
                                </DevButton>
                            </td>
                        </tr>
                        <tr>
                            <th>COUNTRY</th>
                            <DevTd enabled={this.state.country}>
                                {this.state.country
                                    ? this.state.country
                                    : 'OFF'}
                            </DevTd>
                            <td>
                                <input
                                    type="text"
                                    size="2"
                                    value={this.state.countryNew}
                                    onChange={this.handleCountry}
                                />
                            </td>
                            <td>
                                <DevButton onClick={this.setCountry}>
                                    SET
                                </DevButton>
                            </td>
                            <td>
                                <DevButton onClick={this.deleteCountry}>
                                    DELETE
                                </DevButton>
                            </td>
                        </tr>
                        <tr>
                            <th>SENTRY</th>
                            <td>
                                <DevButton onClick={this.simulateErrorOne}>
                                    1/4
                                </DevButton>
                            </td>
                            <td>
                                <DevButton onClick={this.simulateErrorTwo}>
                                    2/4
                                </DevButton>
                            </td>
                            <td>
                                <DevButton onClick={this.simulateErrorThree}>
                                    3/4
                                </DevButton>
                            </td>
                            <td>
                                <DevButton onClick={this.simulateErrorFour}>
                                    4/4
                                </DevButton>
                            </td>
                        </tr>
                        <tr>
                            <th>NEW PATH:</th>
                            <td colSpan="3">
                                <input
                                    type="text"
                                    placeholder="example: '/sg/error'"
                                    value={this.state.gotoURL}
                                    onChange={this.handleGotoURL}
                                />
                            </td>
                            <td>
                                <DevButton onClick={this.gotoNewURL}>
                                    GO
                                </DevButton>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="5">
                                <DevButton onClick={this.reload}>
                                    RELOAD
                                </DevButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default Developer;

import Analytics from '@docomodigital/js-analytics';
import Config from '@docomodigital/js-config';
import Storage from '@docomodigital/js-storage';
import Dictionary from '@docomodigital/js-dictionary';
import Logger from '@docomodigital/js-logger';
import Mfp from '@productfe/mfp';
import User from '@productfe/react-user';
import NewtonAdapter from 'newton-adapter';
import Raven from 'raven-js';
import Useris from '@productfe/useris';

import enableJWT from './enableJWT';
import getRoutes from './getRoutes';
import loadAPI from './loadAPI';
import { breakpoints } from '../styles/responsive';

export default ({ fetcher, BaseComponent }) => {
    let theme;

    return new Promise((resolve, reject) => {
        loadAPI({ fetcher })
            .then(
                ([
                    configResponse,
                    routingResponse,
                    dictResponse,
                    themeResponse,
                ]) => {
                    /*******************
                     * LOGGER
                     ******************/

                    let enableLogger = process.env.NODE_ENV !== 'production';
                    if (!enableLogger) {
                        try {
                            enableLogger = window.localStorage.getItem(
                                'enable-logger'
                            );
                        } catch (e) {}
                    }
                    Logger.init({
                        enable: enableLogger,
                    });

                    /*******************
                     * CONFIG
                     ******************/

                    Config.init({
                        config: Object.assign({}, configResponse, {
                            ROUTING: { ...routingResponse.ROUTING },
                            API_RUNTIME: routingResponse.RUNTIME,
                            MAP_PARAMS: routingResponse.MAP_PARAMS || {},
                        }),
                    });
                    Logger.log('CONFIG', Config.list());
                    Logger.log('ROUTING', Config.get('ROUTING'));
                    Logger.log('API_RUNTIME', Config.get('API_RUNTIME'));

                    /*******************
                     * DICTIONARY
                     ******************/

                    Dictionary.init({
                        dict: dictResponse,
                        showKey:
                            process.env.NODE_ENV === 'production'
                                ? 'standard'
                                : 'missing',
                    });
                    Logger.log('DICTIONARY', Dictionary.list());

                    /*******************
                     * THEME
                     ******************/

                    theme = themeResponse;

                    theme.breakpoints = breakpoints;

                    Logger.log('THEME', theme);

                    /*******************
                     * JWT
                     ******************/

                    enableJWT({ fetcher });

                    /*******************
                     * ANALYTICS
                     ******************/

                    Analytics.init({
                        enabled: Config.get('ENABLE_ANALYTICS'),
                        analyticsID: Config.get('GOOGLE_ANALYTICS_ID'),
                        logger: Logger,
                    });

                    /*******************
                     * JS STORAGE
                     ******************/

                    Storage.init({
                        type: 'cookie',
                        logger: Logger,
                        verbose: true,
                    });

                    /*******************
                     * GLOBALS FOR DEV
                     ******************/

                    if (process.env.NODE_ENV !== 'production') {
                        window.NA = NewtonAdapter;
                        window.CONFIG = configResponse;
                        window.ROUTING = routingResponse;
                        window.DICT = dictResponse;
                    }

                    /*******************
                     * RAVEN
                     ******************/

                    return new Promise(ravenResolve => {
                        if (
                            Config.get('SENTRY_ENABLE') &&
                            Config.get('SENTRY_URL')
                        ) {
                            Raven.config(
                                Config.get('SENTRY_URL'),
                                process.env.REACT_APP_RELEASE && {
                                    release: process.env.REACT_APP_RELEASE,
                                }
                            )
                                .install()
                                .context(() => {
                                    ravenResolve(Raven);
                                });
                        } else {
                            ravenResolve();
                        }
                    });
                }
            )
            .then(ravenInstance => {
                /*******************
                 * NEWTON ADAPTER
                 ******************/

                return NewtonAdapter.init({
                    logger: Logger,
                    enable: Config.get('ENABLE_NEWTON_TRACKING'),
                    waitLogin: true,
                    secretId: Config.get('NEWTON_SECRETID'),
                    config: {
                        whiteLabel: Config.get('WHITE_LABEL'),
                        isInternational: Config.get('IS_INTERNATIONAL')
                            ? true
                            : false,
                        ravenInstance,
                    },
                    properties: {},
                });
            })
            .then(() => {
                /*******************
                 * USER
                 ******************/

                return User.init({
                    config: Config,
                    dict: Dictionary,
                    fetcher: fetcher.get.bind(fetcher),
                    logger: Logger,
                    storage: Storage,
                });
            })
            .then(() => {
                /*******************
                 * LOGVIEW
                 ******************/

                if (Config.get('SETTRACK_PARAMS')) {
                    NewtonAdapter.setLogView(Config.get('SETTRACK_PARAMS'));
                }

                /*******************
                 * USERIS
                 ******************/

                Useris.init({
                    domain: Config.get('DEST_DOMAIN'),
                    country: Config.get('REAL_COUNTRY'),
                    enableAliasCheck: Config.get('ENABLE_ALIAS_URL'),
                    aliasCheckUrl: Config.get('ALIAS_URL'),
                    disablePinInput: Config.get('HIDE_LOGINPIN'),
                    hybridnews: 0,
                    store_type: '',
                    apikey: Config.get('MOTIME_API_KEY'),
                    enableGoogleCheckbox: Config.get('SHOW_GOOGLE_CHECKBOX'),
                    emailIdentityEnabled: Config.get('IDENTITY_EMAIL_ENABLED'),
                    identityFbEnabled: Config.get('IDENTITY_FB_ENABLED'),
                    redirectToMipRegisterAtStart: Config.get(
                        'REDIRECT_TO_MIP_REGISTER_AT_START'
                    ),
                    logger: Logger,
                    regexObject: {
                        msisdn: Config.get(
                            'COUNTRY_REGEXP_VALIDATE_PLATINUM_MSISDN'
                        ),
                        pin: Config.get('COUNTRY_REGEXP_VALIDATE_PLATINUM_PIN'),
                        password: Config.get('REGEXP_VALIDATE_PASSWD') || '.',
                        // eslint-disable-next-line no-useless-escape
                        email:
                            Config.get('REGEXP_VALIDATE_EMAIL') || '.+@.+..+',
                        generic: '.',
                        genericPassword: '.',
                    },
                });

                /*******************
                 * MFP
                 ******************/

                Mfp.init({
                    enableNewtonUser: Config.get('ENABLE_NEWTON_USER'),
                    motimeApiKey:
                        Config.get('MOTIME_API_KEY') || Config.get('API_KEY'),
                    apiCountry:
                        Config.get('MFP_CONTENT_INAPP_API_COUNTRY') ||
                        Config.get('API_COUNTRY'),
                    inappCountry:
                        Config.get('MFP_CONTENT_INAPP_TLD') ||
                        Config.get('TLD'),
                    fpnamespace:
                        Config.get('MFP_NAMESPACE') ||
                        Config.get('SITE_PROFILE'),
                    bTestId: Config.get('B_TEST_ID'),
                    country: Config.get('MFP_TLD') || Config.get('TLD'),
                    expireData: Config.get('MFP_EXPIRE') || 3600,
                    createPonyUrl: `${Config.get('MOA_API_CREATEPONY')}?`,
                    mfpPutUrl: `${Config.get('MFP_API_URL')}put?`,
                    destDomain: Config.get('DEST_DOMAIN'),
                    goToStoreUrl: Config.get('GOTOSTORE_URL'),
                });

                /*******************
                 * ROUTES
                 ******************/

                const routes = getRoutes({ BaseComponent });
                resolve({ routes, theme });
            })
            .catch(err => {
                Logger.error('BOOTSTRAP', err);
                reject(err);
            });
    });
};

import Config from '@docomodigital/js-config';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import { withRouter } from 'react-router';
import Dictionary from '@docomodigital/js-dictionary';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Button from 'components/Button/Button.jsx';
import Card from 'components/Card/Card.jsx';
import Container from 'components/Container/Container.jsx';
import List from 'components/List/List.jsx';
import LoaderList from 'components/LoaderList/LoaderList.jsx';

/* LIBRARIES */
import fetcher from 'lib/fetcher';
import parseApiParams from 'lib/parseApiParams';

class MixedListCategoryItems extends Component {
    constructor(props) {
        super(props);
        this.size = 10;
        this.state = {
            loading: true,
            items: [],
            offset: 0,
            loadMore: false,
            totalItems: 0,
        };
        this.contentsApi = Config.get('API_RUNTIME').CONTENTS_BY_CATEGORY;
        this.imageSizes = Config.get('IMG_SIZES');
        this.loadItems = this.loadItems.bind(this);
    }

    loadItems() {
        // if(!this.state.items.length && this.props.active){

        // inizialize total items
        let totalItems = this.state.totalItems;

        // loading state turned on
        this.setState({ loading: true });

        // get API url and params
        const contentsApiUrl = this.contentsApi.url;
        const contentsApiParams = parseApiParams(
            Object.assign({}, this.contentsApi.params, {
                category: this.props.category,
                size: this.size,
                offset: this.state.offset,
            }),
            this.props.match.params
        );

        // call API
        const apiCall = totalItems
            ? fetcher.get(contentsApiUrl, contentsApiParams, {}, true)
            : fetcher
                  .get(contentsApiUrl, contentsApiParams, {}, false)
                  .then(response => {
                      totalItems = parseInt(
                          response.headers.get('X-Available-Contents')
                      );
                      return response.json();
                  });

        // set new state
        apiCall.then(newItems => {
            this.setState(prevState => {
                const items = [...prevState.items, ...newItems];
                return {
                    loading: false,
                    items,
                    offset: prevState.offset + this.size,
                    loadMore:
                        items.length < totalItems &&
                        newItems.length === this.size,
                    totalItems,
                };
            });
        });
    }

    componentDidMount() {
        this.loadItems();
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.active !== this.props.active && this.props.active) {
    //         this.loadItems();
    //     }
    // }

    render() {
        const { active } = this.props;
        const { items, loading, loadMore } = this.state;

        if (!active) {
            return null;
        } else if (!loading && !items.length) {
            Raven.captureException(
                new Error(`No items for category ${this.props.category}`)
            );
            return (
                <Block
                    bg="bgBox1"
                    p={[0, 1, 2]}
                    borderBottom="1px solid"
                    borderColor="w"
                    boxShadow={1}
                >
                    {Dictionary.get('WEBAPP_DDC_SEARCH_NORESULTS')}
                </Block>
            );
        } else {
            const itemsCards = items.map((item, index) => (
                <Card
                    key={index}
                    id={item.id}
                    link={item.url_zoom}
                    title={item.title}
                    imageProps={{
                        src: item.images.cover.ratio_1_4,
                        ratio: 'video',
                        width: this.imageSizes.cover.medium,
                        alt: item.title,
                    }}
                    container={item.container}
                    contentType={item.format}
                    borderRadius={[1]}
                    p={1}
                    fromPage="MixedListCategory"
                    eventName="Content_click"
                />
            ));

            return (
                <Block
                    bg="bgBox1"
                    p={[0, 1, 2]}
                    borderBottom="1px solid"
                    borderColor="w"
                    boxShadow={1}
                >
                    <Container maxWidth={0}>
                        <List columns={[2, 3, 4, 4]}>{itemsCards}</List>

                        {loading && <LoaderList />}
                    </Container>

                    {loadMore && !loading && (
                        <Block mb={1} mt={1} textAlign="center">
                            <Button
                                linearGradient={['w', 'w']}
                                borderColor="secondary"
                                color="primary"
                                boxShadow={5}
                                textAlign="center"
                                border={[0]}
                                borderRadius={[0]}
                                onClick={this.loadItems}
                            >
                                {Dictionary.get('WEBAPP_BUTTON_ALLITEMS')}
                            </Button>
                        </Block>
                    )}
                </Block>
            );
        }
    }
}

MixedListCategoryItems.propTypes = {
    category: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
};

export default withRouter(MixedListCategoryItems);

import Config from '@docomodigital/js-config';
import Logger from '@docomodigital/js-logger';
import { UserFeedback } from '@productfe/react-user';
import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import Moment from 'moment';
import Smoothscroll from 'smoothscroll-polyfill';

/* GLOBAL CSS */
import 'styles/index.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';

/* FONTS */
import Fonts from 'fonts/_fonts';

/* BOOTSTRAP */
import bootstrap from 'bootstrap/bootstrap';

/* REDUX */
import store from 'model/store';
import { setContainers } from 'model/actions';

/* LOCAL DEPENDENCIES */
import fetcher from 'lib/fetcher';
import BaseComponent from 'pages/_base/Base';
import SmartBanner from 'components/SmartBanner/SmartBanner';

/*******************
 * ROUTING
 *******************/

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isSmartBannerShown: true,
        };
    }

    hideSmartBanner = () => {
        this.setState({ isSmartBannerShown: false });
    };

    componentDidMount() {
        store.dispatch(setContainers(this.props.containers));
    }

    render() {
        const supportsHistory = 'pushState' in window.history;
        const template = (
            <ReduxProvider store={store}>
                <React.Fragment>
                    <UserFeedback />
                    {this.state.isSmartBannerShown && (
                        <SmartBanner
                            key="smartbanner"
                            serviceIcon={Config.get('ICO_SERVICE')}
                            onClose={this.hideSmartBanner}
                        />
                    )}
                    <BrowserRouter forceRefresh={!supportsHistory}>
                        <Switch>{this.props.routes}</Switch>
                    </BrowserRouter>
                    <Fonts />
                </React.Fragment>
            </ReduxProvider>
        );
        if (!this.props.theme) {
            Logger.warn('APP', `Theme not defined`);
            return template;
        } else {
            return (
                <ThemeProvider theme={this.props.theme}>
                    {template}
                </ThemeProvider>
            );
        }
    }
}

/*******************
 * INITIALIZATION
 *******************/

bootstrap({ fetcher, BaseComponent })
    .then(({ routes, theme }) => {
        // external dependencies
        Moment.locale(Config.get('LANGUAGE'));
        Smoothscroll.polyfill();

        // call MULTICATALOG runtime API
        fetcher
            .get(Config.get('API_RUNTIME').MULTICATALOG_LIST.url)
            .then(containers => {
                // log multicatalog
                Logger.log('MULTICATALOG API', containers);
                // run app
                ReactDOM.render(
                    <App
                        containers={containers}
                        routes={routes}
                        theme={theme}
                    />,
                    document.getElementById('ddk-app-root')
                );
            });
    })
    .catch(error => {
        console.error(error);
    });

import { themeGet } from 'styled-system';

export default props => {
    if (props.linearGradient) {
        let [topValue, bottomValue] = props.linearGradient;
        let newValues = [topValue, bottomValue];
        if (themeGet(`colors.${topValue}`)(props)) {
            newValues[0] = themeGet(`colors.${topValue}`)(props);
        }
        if (themeGet(`colors.${bottomValue}`)(props)) {
            newValues[1] = themeGet(`colors.${bottomValue}`)(props);
        }
        return newValues;
    } else {
        return null;
    }
};

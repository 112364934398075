import React, { PureComponent } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import Analytics from '@docomodigital/js-analytics';
import NewtonAdapter from 'newton-adapter';
import { connect } from 'react-redux';
import Dictionary from '@docomodigital/js-dictionary';

/* COMPONENTS */
import Banner from 'components/Banner/Banner.jsx';
import Block from 'components/Block/Block.jsx';
import Container from 'components/Container/Container.jsx';
import Text from 'components/Text/Text.jsx';

/* SUB-PAGES */
import MixedListHighlights from 'pages/MixedList/MixedListHighlights';
import MixedListCategory from 'pages/MixedList/MixedListCategory';
import apiByType from 'lib/formatter/apiByType';

class MixedList extends PureComponent {
    constructor(props) {
        super(props);
        this.trackTabClick = this.trackTabClick.bind(this);
    }

    trackTabClick(content_type) {
        const eventName = 'Tab_click';
        Analytics.trackEvent({
            category: 'MixedList',
            action: eventName,
            label: content_type,
        });
        NewtonAdapter.trackEvent({
            name: eventName,
            properties: { content_type },
        });
    }

    render() {
        const isVideos = this.props.location.pathname.indexOf('video') !== -1;
        const isGames = this.props.location.pathname.indexOf('games') !== -1;
        const isHighlighted = !isVideos && !isGames;

        const categoriesVideo = this.props.compileData[0].filter(
            item => item.format === 'video'
        );
        const categoriesGames = this.props.compileData[0].filter(
            item => item.format !== 'video'
        );

        const cleanCompileData = apiByType(this.props.compileData[1]);
        const highlightMix = cleanCompileData.top_carousel;
        const highlightVideo = cleanCompileData.video;
        const highlightGames = cleanCompileData.games;

        return (
            <React.Fragment>
                <Banner
                    backArrowColor={
                        this.props.selectedContainer.theme.primaryColor
                    }
                    linearGradient={[
                        this.props.selectedContainer.theme.gradientTop,
                        this.props.selectedContainer.theme.gradientBottom,
                    ]}
                    src={this.props.selectedContainer.images.cover}
                    alt={this.props.selectedContainer.name}
                />

                <Block bg={this.props.selectedContainer.theme.gradientBottom}>
                    <Container maxWidth={0}>
                        <Block
                            is="ul"
                            m="auto"
                            pb={2}
                            pt={1}
                            textAlign="center"
                        >
                            <Link
                                to={`${this.props.match.url}/highlighted`}
                                title={Dictionary.get('WEBAPP_DDC_TYPE1')}
                            >
                                <Text
                                    is="li"
                                    display="inline-block"
                                    ml={1}
                                    mr={1}
                                    uppercase
                                    borderBottom={isHighlighted ? '1' : '3'}
                                    color={
                                        this.props.selectedContainer.theme
                                            .secondaryColor
                                    }
                                    borderColor={
                                        this.props.selectedContainer.theme
                                            .secondaryColor
                                    }
                                >
                                    {Dictionary.get('WEBAPP_DDC_TYPE1')}
                                </Text>
                            </Link>

                            {categoriesVideo.length ? (
                                <Link
                                    to={`${this.props.match.url}/video`}
                                    onClick={() => this.trackTabClick('video')}
                                    title={Dictionary.get('WEBAPP_DDC_TYPE2')}
                                >
                                    <Text
                                        is="li"
                                        display="inline-block"
                                        ml={1}
                                        mr={1}
                                        uppercase
                                        borderBottom={isVideos ? '1' : '3'}
                                        color={
                                            this.props.selectedContainer.theme
                                                .secondaryColor
                                        }
                                        borderColor={
                                            this.props.selectedContainer.theme
                                                .secondaryColor
                                        }
                                    >
                                        {Dictionary.get('WEBAPP_DDC_TYPE2')}
                                    </Text>
                                </Link>
                            ) : null}

                            {categoriesGames.length ? (
                                <Link
                                    to={`${this.props.match.url}/games`}
                                    onClick={() => this.trackTabClick('games')}
                                    title={Dictionary.get('WEBAPP_DDC_TYPE3')}
                                >
                                    <Text
                                        is="li"
                                        display="inline-block"
                                        ml={1}
                                        mr={1}
                                        uppercase
                                        borderBottom={isGames ? '1' : '3'}
                                        color={
                                            this.props.selectedContainer.theme
                                                .secondaryColor
                                        }
                                        borderColor={
                                            this.props.selectedContainer.theme
                                                .secondaryColor
                                        }
                                    >
                                        {Dictionary.get('WEBAPP_DDC_TYPE3')}
                                    </Text>
                                </Link>
                            ) : null}
                        </Block>
                    </Container>
                </Block>

                <Switch>
                    <Route
                        path={`${this.props.match.path}/(highlighted)?`}
                        exact
                        render={props => (
                            <MixedListHighlights
                                mixed={highlightMix}
                                video={highlightVideo}
                                categories={categoriesVideo}
                                games={highlightGames}
                                bgColor={
                                    this.props.selectedContainer.theme
                                        .gradientBottom
                                }
                                fgColor={
                                    this.props.selectedContainer.theme
                                        .secondaryColor
                                }
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path={`${this.props.match.path}/video/:categoryId?`}
                        render={props => (
                            <MixedListCategory
                                type={2}
                                categories={categoriesVideo}
                                {...props}
                            />
                        )}
                    />
                    <Route
                        path={`${this.props.match.path}/games`}
                        render={props => (
                            <MixedListCategory
                                type={3}
                                categories={categoriesGames}
                                {...props}
                            />
                        )}
                    />
                </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => ({
    selectedContainer: state.containers.filter(
        container => container.container === props.match.params.container
    )[0],
});

export default connect(mapStateToProps)(MixedList);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Placeholder } from './Placeholder';
import Config from '@docomodigital/js-config';
import ImageStyle from './ImageStyle';

const isIntersecting = element =>
    element.isIntersecting || element.intersectionRatio > 0;

const Wrapper = styled.div`
    position: relative;
    display: block;
    height: 0;
    background: transparent;
`;

const LoadedImage = styled(ImageStyle)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${props => (props.isLoaded ? 1 : 0)};
    transition: opacity 300ms, 300ms linear;
`;

class LazyImage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            src: this.props.src,
            isLoaded: false,
        };
        this.containerRef = React.createRef();
        this.imageRef = React.createRef();
    }

    onImgLoad = () =>
        this.setState({ isLoaded: true }, () => {
            if (typeof this.props.onLoad === 'function') {
                this.props.onLoad();
            }
        });

    onImgError = () =>
        this.setState({ isLoaded: false }, () => {
            if (typeof this.props.onError === 'function') {
                this.props.onError();
            }
        });

    loadImage() {
        if (this.imageRef.current) {
            this.imageRef.current.src = this.state.src;
        }
    }

    componentDidMount() {
        const options = {
            root: null,
            rootMargin: '10px',
            threshold: 0.1,
        };

        this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (isIntersecting(entry)) {
                    this.loadImage();
                    this.observer.unobserve(this.containerRef.current);
                }
            }, options);
        });
        this.observer.observe(this.containerRef.current);
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.unobserve(this.containerRef.current);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.isLoaded && this.state.src) {
            this.loadImage();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.src !== nextProps.src) {
            return {
                isLoaded: false,
                src: nextProps.src,
            };
        }
        return null;
    }

    getRatioFromConfig() {
        const { ratio } = this.props;
        return Config.get('IMG_RATIO') && Config.get('IMG_RATIO')[ratio]
            ? Config.get('IMG_RATIO')[ratio]
            : [4, 3];
    }

    renderPlaceholder() {
        const { width, method, ratio } = this.props;
        return <Placeholder width={width} method={method} ratio={ratio} />;
    }

    render() {
        const { width, src, ratio, ...imageProps } = this.props;

        const [ratioWidth, ratioHeight] = this.getRatioFromConfig();
        const height = Math.round((width / ratioWidth) * ratioHeight);
        const padding = (height / width) * 100;

        return (
            <Wrapper
                ref={this.containerRef}
                style={{
                    paddingBottom: `${padding}%`,
                }}
            >
                {!this.state.isLoaded && this.renderPlaceholder()}
                <LoadedImage
                    ref={this.imageRef}
                    {...imageProps}
                    onLoad={this.onImgLoad}
                    onError={this.onImgError}
                    isLoaded={this.state.isLoaded}
                />
            </Wrapper>
        );
    }
}

LazyImage.propTypes = {
    src: PropTypes.string.isRequired,
    ratio: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    method: PropTypes.string,
};

export default LazyImage;

import { FadeIn } from 'animate-css-styled-components';
import React, { PureComponent } from 'react';
import Analytics from '@docomodigital/js-analytics';
import Dictionary from '@docomodigital/js-dictionary';
import NewtonAdapter from 'newton-adapter';
import { Helmet } from 'react-helmet';
import { withTheme } from 'styled-components/macro';
import Config from '@docomodigital/js-config';
/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Carousel from 'components/Carousel/Carousel.jsx';
import Container from 'components/Container/Container.jsx';
import Image from 'components/Image/Image.jsx';
import Text from 'components/Text/Text.jsx';

/* LIBRARIES */
import imageSize from 'lib/formatter/imageSize';

/* SUB-PAGES */
import MixedListCategoryItems from 'pages/MixedList/MixedListCategoryItems';

class MixedListCategory extends PureComponent {
    constructor(props) {
        super(props);

        // find the category index from the url
        if (props.match.params.categoryId) {
            const currentCategory = props.categories.findIndex(
                x => x.id === props.match.params.categoryId
            );
            props.categories.concat(
                props.categories.splice(0, currentCategory)
            );
        }
        this.state = {
            currentCategory: 0,
        };
        // NOTE: bind of changeCategory is before definition of carouselSettings purposely
        this.changeCategory = this.changeCategory.bind(this);
        this.carouselSettings = {
            afterChange: this.changeCategory,
            arrows: true,
            autoplay: false,
            centerMode: true,
            centerPadding: '300px',
            dots: false,
            focusOnSelect: true,
            slideToShow: 3,
            responsive: [
                {
                    breakpoint: this.props.theme.breakpoints[1],
                    settings: { centerPadding: '100px', arrows: false },
                },
            ],
        };

        this.imageSizes = Config.get('IMG_SIZES');

        // set title
        const customTitle = Dictionary.get(
            `SEO_WEBAPP_SITE_TYPE${
                props.type
            }_TITLE_${props.match.params.container.toUpperCase()}`
        );
        this.title =
            customTitle ||
            Dictionary.get(`SEO_WEBAPP_SITE_TYPE${props.type}_TITLE_DEFAULT`);

        // set description
        const customDescription = Dictionary.get(
            `SEO_WEBAPP_SITE_TYPE${
                props.type
            }_DESCRIPTION_${props.match.params.container.toUpperCase()}`
        );
        this.description =
            customDescription ||
            Dictionary.get(
                `SEO_WEBAPP_SITE_TYPE${props.type}_DESCRIPTION_DEFAULT`
            );
    }

    changeCategory(index) {
        /* TRACKING */
        const eventName = 'Category_click';
        const clickedCategory = this.props.categories[index];
        Analytics.trackEvent({
            category: 'MixedListCategory',
            action: eventName,
            label: clickedCategory.title,
        });
        NewtonAdapter.trackEvent({
            name: eventName,
            properties: {
                category_ID: clickedCategory.id,
                category_title: clickedCategory.title,
            },
        });

        /* CHANGE CURRENT CATEGORY ITEMS */
        this.setState({ currentCategory: index });
    }

    render() {
        const categoriesImages = this.props.categories.map(
            (category, index) => (
                <Block key={index} p={1}>
                    <Image
                        src={imageSize(
                            category.images.cover.ratio_1_4,
                            'video',
                            this.imageSizes.cover.large
                        )}
                        alt={category.title}
                        border={index === this.state.currentCategory ? 5 : 1}
                        borderColor={
                            index === this.state.currentCategory
                                ? 'bgContent'
                                : 'w'
                        }
                        borderRadius={1}
                        filter={0}
                    />
                </Block>
            )
        );

        const categoriesItems = this.props.categories.map((category, index) => (
            <MixedListCategoryItems
                key={category.id}
                category={category.id}
                active={
                    category.id ===
                    this.props.categories[this.state.currentCategory].id
                }
            />
        ));

        return [
            <Helmet key="helmet">
                <title>{this.title}</title>
                <meta name="description" content={this.description} />
            </Helmet>,
            <FadeIn key="template">
                <Block {...this.props}>
                    <Container>
                        {this.props.categories.length > 1 ? (
                            <React.Fragment>
                                <Carousel
                                    settings={this.carouselSettings}
                                    maxWidth={[6, 5, 7]}
                                    arrowsColor="primary"
                                >
                                    {categoriesImages}
                                </Carousel>

                                <Block m={1} textAlign="center">
                                    <Text is="h2" fontSize={[2, 3]} uppercase>
                                        {
                                            this.props.categories[
                                                this.state.currentCategory
                                            ].title
                                        }
                                    </Text>
                                    <Text>
                                        {
                                            this.props.categories[
                                                this.state.currentCategory
                                            ].num_items
                                        }{' '}
                                        items
                                    </Text>
                                </Block>
                            </React.Fragment>
                        ) : null}

                        {categoriesItems}
                    </Container>
                </Block>
            </FadeIn>,
        ];
    }
}

export default withTheme(MixedListCategory);

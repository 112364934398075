import styled from 'styled-components/macro';
import { themeGet } from 'styled-system';

import Block from 'components/Block/Block.jsx';

export default styled(Block).attrs(props => ({
    fixed: props.fixed || themeGet('footerFixed')(props),
    bg: props.bg || 'footerBg',
    minHeight: props.minHeight || themeGet('footerheight')(props),
    p: props.p || [0, 1, 2],
    fontSize: props.fontSize || [0, 1],
    textAlign: props.textAlign || 'center',
    boxShadow: props.textAlign || [6],
}))`
    display: table-row;
    height: 50px;
    clear: both;
`;

import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import montserratEOT from './montserrat-regular.eot';
import montserratSVG from './montserrat-regular.svg';
import montserratTTF from './montserrat-regular.ttf';
import montserratWOFF from './montserrat-regular.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'montserratregular';
        font-display: auto;
        src: url(${staticPrefix + montserratWOFF}) format('woff'),
            url(${staticPrefix + montserratTTF}) format('truetype'),   
            url(${staticPrefix + montserratEOT}),
            url(${staticPrefix +
                montserratEOT}) format('embedded-opentype'),        
            url(${staticPrefix + montserratSVG}) format('svg');
        font-weight: normal;
        font-style: normal;    
    }
`;

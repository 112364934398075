import React from 'react';
import PropTypes from 'prop-types';

/* STYLE */
import IconStyle from './IconStyle';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

const Icon = props => (
    <Block {...props}>
        <IconStyle
            is="span"
            className={`icon-${props.content}`}
            fontSize={props.fontSize || null}
            lineHeight={props.lineHeight || null}
        />
    </Block>
);

Icon.propTypes = {
    theme: PropTypes.object,
    content: PropTypes.string.isRequired,
};

Icon.defaultProps = {
    is: 'div',
};

export default Icon;

import React from 'react';

const Base = React.forwardRef(
    (
        {
            is: Component,
            alignItems,
            arrowsColor,
            backgroundImage,
            backgroundPosition,
            backgroundRepeat,
            backgroundSize,
            badgeAlt,
            badgeSrc,
            bgColor,
            borderBottom,
            borderColor,
            borderRadius,
            boxShadow,
            compileData,
            contentType,
            currentSlide,
            dispatch,
            ellipsis,
            fgColor,
            filter,
            fromPage,
            gridGap,
            gridTemplateColumns,
            imageSrc,
            imageAlt,
            imagePointerFix,
            imageProps,
            isLoaded,
            hasMargins,
            justifyContent,
            linearGradient,
            lineHeight,
            maxWidth,
            maxHeight,
            minHeight,
            minWidth,
            pointerFix,
            routeConfig,
            selectedContainer,
            staticContext,
            slideCount,
            swipe,
            textAlign,
            type,
            uppercase,
            verticalAlign,
            zIndex,
            ...props
        },
        ref
    ) => <Component {...props} ref={ref} />
);

export default Base;

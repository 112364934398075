import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import robotoEOT from './oswald-regular.eot';
import robotoSVG from './oswald-regular.svg';
import robotoTTF from './oswald-regular.ttf';
import robotoWOFF from './oswald-regular.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'oswaldregular';
        font-display: auto;
        src: url(${staticPrefix + robotoWOFF}) format('woff'),
            url(${staticPrefix + robotoTTF}) format('truetype'),   
            url(${staticPrefix + robotoEOT}),
            url(${staticPrefix +
                robotoEOT}) format('embedded-opentype'),        
            url(${staticPrefix + robotoSVG}) format('svg');
    }
`;

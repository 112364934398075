import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import tenorsansEOT from './tenorsans-regular-webfont.eot';
import tenorsansSVG from './tenorsans-regular-webfont.svg';
import tenorsansTTF from './tenorsans-regular-webfont.ttf';
import tenorsansWOFF from './tenorsans-regular-webfont.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'tenor_sansregular';
        font-display: auto;
        src: url(${staticPrefix + tenorsansWOFF}) format('woff'),
            url(${staticPrefix + tenorsansTTF}) format('truetype'),   
            url(${staticPrefix + tenorsansTTF}),
            url(${staticPrefix +
                tenorsansEOT}) format('embedded-opentype'),        
            url(${staticPrefix + tenorsansSVG}) format('svg');
            font-weight: normal;
            font-style: normal;
    }
`;

import styled, { keyframes } from 'styled-components/macro';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

const Slide = keyframes`
  0%{background-position: 50000px 0}
  100%{background-position: 0 0;}
`;

export default styled(Block)`
    height: 270px;
    width: 100vw;
    animation: ${Slide} 1000s linear infinite;
    background-position: left bottom;
    background-repeat: repeat-x;
    background-size: auto;
    @media only screen and (min-width: 768px) {
        background-size: auto;
    }
`;

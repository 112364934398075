import Analytics from '@docomodigital/js-analytics';
import Config from '@docomodigital/js-config';
import Logger from '@docomodigital/js-logger';
import Dict from '@docomodigital/js-dictionary';
import Platform from '@docomodigital/js-platform';
import Storage from '@docomodigital/js-storage';
import Mfp from '@productfe/mfp';
import User from '@productfe/react-user';
import NewtonAdapter from 'newton-adapter';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Image from 'components/Image/Image.jsx';

/* STYLES */
import {
    BoxPosition,
    GenericFlex,
    InvisibleLink,
    ListFlex,
    IconService,
    LinkDownload,
    MessageTxt,
    TitleBanner,
    TextBanner,
    IconClose,
} from './SmartBannerStyle';

class SmartBanner extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            storeUrl: '',
            showSmartBanner: false,
            pony: '',
        };

        this.invisibleButtonRef = null;

        this.close = this.close.bind(this);
        this.trackAndClose = this.trackAndClose.bind(this);
        this.downloadAndClose = this.downloadAndClose.bind(this);
    }

    componentDidMount() {
        // const _this = this;

        // set callback to MFP put
        window._mfpPutCallback = resp => {
            Logger.log('SMART BANNER', 'MFP PUT', resp);

            this.setState(
                {
                    showSmartBanner: true,
                    storeUrl: `${Mfp.generateRedirectUrl(this.state.pony)}`,
                },
                () => {
                    // execute onView method, if defined
                    if (this.props.onView) {
                        this.props.onView();
                    }
                }
            );
        };

        // get conditions
        const conditionsToShow =
            !!Config.get('GOTOSTORE_URL') &&
            (Platform.OS === 'android' || Platform.OS === 'ios') &&
            Config.get('SMART_BANNER_ENABLED') &&
            !Storage.get('HIDE_SMART_BANNER') &&
            (!Config.get('SHOW_ADV_FOR_LOGGED') || User.isLogged());

        // log conditions
        Logger.log('SMART BANNER', conditionsToShow, {
            url: [!!Config.get('GOTOSTORE_URL'), Config.get('GOTOSTORE_URL')],
            os: [
                Platform.OS === 'android' || Platform.OS === 'ios',
                Platform.OS,
            ],
            enabled: Config.get('SMART_BANNER_ENABLED'),
            cookieHide: [
                !Storage.get('HIDE_SMART_BANNER'),
                Storage.get('HIDE_SMART_BANNER'),
            ],
            advLogged: [
                !Config.get('SHOW_ADV_FOR_LOGGED') || User.isLogged(),
                !Config.get('SHOW_ADV_FOR_LOGGED'),
                User.isLogged(),
            ],
        });

        // check conditions
        if (conditionsToShow) {
            if (User.isLogged()) {
                NewtonAdapter.getTransientToken((err, resp) => {
                    if (err) {
                        Logger.error('SMART BANNER', err);
                    } else {
                        // create pony for MFP
                        Mfp.createPony({
                            userId: resp ? resp : '',
                            sessionId: NewtonAdapter.getSessionId() || '',
                        })
                            .then(resp => {
                                // set pony for MFP
                                this.setState(
                                    {
                                        pony: resp.ponyUrl,
                                    },
                                    () => {
                                        // start MFP flow
                                        Mfp.startMfpFlow(this.state.pony);

                                        Logger.log(
                                            'SMART BANNER',
                                            'CREATE PONY',
                                            this.state.pony
                                        );
                                    }
                                );
                            })
                            .catch(function(err) {
                                Logger.error('SMART BANNER', err);
                            });
                    }
                });
            } else {
                window._mfpPutCallback({
                    message:
                        'User is not logged, no mfp.put has been called in SmartBanner',
                });
            }
        }
    }

    close() {
        // hide smart banner
        this.setState({ showSmartBanner: false });

        // execute onClose method, if defined
        if (this.props.onClose) {
            this.props.onClose();
        }

        // don't show again in this device
        if (!Config.get('IS_PROMO_SMARTBANNER')) {
            Storage.set('HIDE_SMART_BANNER', 1, {
                type: 'cookie',
                exdays: Config.get('SMART_BANNER_REMINDER'),
            });
        }
    }

    trackAndClose() {
        // event tracking
        Analytics.trackEvent({
            category: 'SmartBanner',
            action: 'CloseAppPromoClick',
            label: window.location.href,
        });
        NewtonAdapter.trackEvent({
            name: 'CloseAppPromoClick',
            properties: {
                category: 'Behavior',
                action: 'CloseAppPromoClick',
                label: window.location.href,
            },
        });

        // close smart banner
        this.close();
    }

    downloadAndClose(e) {
        e.preventDefault();

        // event tracking
        Analytics.trackEvent({
            category: 'SmartBanner',
            action: 'NativeAppPromoClick',
            label: window.location.href,
        });
        NewtonAdapter.trackEvent({
            name: 'NativeAppPromoClick',
            properties: {
                category: 'Behavior',
                action: 'NativeAppPromoClick',
                label: window.location.href,
            },
        });

        // close smart banner
        this.close();

        // click on invisible button
        this.invisibleButtonRef.click(e);
    }

    render() {
        return this.state.showSmartBanner ? (
            <BoxPosition>
                <GenericFlex>
                    <ListFlex>
                        <IconClose onClick={this.trackAndClose}>
                            <Image
                                src={Config.get('CLOSE')}
                                alt=""
                                width="15px"
                                m="auto"
                            />
                        </IconClose>
                    </ListFlex>
                    <ListFlex>
                        <IconService
                            style={{
                                backgroundImage: `url(${Config.get(
                                    'ICO_SERVICE'
                                )})`,
                            }}
                            onClick={this.trackAndClose}
                        />
                    </ListFlex>
                    <ListFlex>
                        <TitleBanner>
                            {Dict.get('PLAY_STORE_TITLE')}
                        </TitleBanner>
                        <TextBanner>{Dict.get('PLAY_STORE_AUTHOR')}</TextBanner>
                        <TextBanner>{Dict.get('PLAY_STORE_PRICE')}</TextBanner>
                    </ListFlex>
                    <ListFlex>
                        <LinkDownload onClick={this.downloadAndClose}>
                            {Dict.get('WEBAPP_BUTTON_DOWNLOAD')}
                        </LinkDownload>
                    </ListFlex>

                    {Platform.OS === 'android' &&
                        Config.get('SMART_BANNER_MESSAGE_ENABLED') && (
                            <MessageTxt>
                                <p>{Dict.get('WEBAPP_SMART_BANNER_MESSAGE')}</p>
                            </MessageTxt>
                        )}

                    <InvisibleLink
                        id="invisibleDownloadButton"
                        ref={invisbleLink => {
                            this.invisibleButtonRef = invisbleLink;
                        }}
                        href={`javascript:window.location.href='${this.state.storeUrl}'; 0;`}
                    >
                        invisibleButton
                    </InvisibleLink>
                </GenericFlex>
            </BoxPosition>
        ) : null;
    }
}

SmartBanner.propTypes = {
    serviceIcon: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    onView: PropTypes.func,
};

export default SmartBanner;

import React from 'react';
import Config from '@docomodigital/js-config';
import { Route } from 'react-router-dom';

export default ({ BaseComponent }) => {
    const configRouting = Config.get('ROUTING');

    const routes = [];
    let fallback = { key: '', route: null };

    const addRoute = (key, route) => {
        routes.push(
            <Route
                key={key}
                path={route.path}
                exact={route.exact || false}
                render={routeProps => {
                    const componentName = route.component;
                    const routeConfig = Object.assign({ key }, route);
                    return (
                        <BaseComponent
                            component={componentName}
                            routeConfig={routeConfig}
                            {...routeProps}
                        />
                    );
                }}
            />
        );
    };

    for (const key in configRouting) {
        if (Object.prototype.hasOwnProperty.call(configRouting, key)) {
            // for fallback route, don't add it to routes now but save it for later (*)
            if (configRouting[key].fallback) {
                fallback = { key, route: configRouting[key] };
            } else {
                addRoute(key, configRouting[key]);
            }
        }
    }

    // (*) add fallback route as last route, if exists
    if (fallback.route) {
        addRoute(fallback.key, fallback.route);
    }

    return routes;
};

import React from 'react';
import { linearGradientPropTypes } from 'props-to-styled';
import PropTypes from 'prop-types';

/* STYLE */
import ButtonStyle from './ButtonStyle';

const Button = props => <ButtonStyle {...props}>{props.children}</ButtonStyle>;

Button.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
    ...linearGradientPropTypes,
};

Button.defaultProps = {
    is: 'button',
};

export default Button;

import styled from 'styled-components/macro';
import {
    borderColor,
    textAlign,
    position,
    top,
    right,
    bottom,
    left,
    space,
    color,
} from 'styled-system';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

export default styled(Block)`
    position: relative;
    ${borderColor};
    ${textAlign};
    ${position};
    ${top};
    ${right};
    ${bottom};
    ${left};
    ${space};
    ${color};
`;

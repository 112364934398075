import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import imageSize from '../../lib/formatter/imageSize';
import Config from '@docomodigital/js-config';
import { PlaceholderManager } from '../../lib/placeholder';
import ImageStyle from './ImageStyle';

export class Placeholder extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            url: null,
        };
        this.ready = true;
    }

    async UNSAFE_componentWillMount() {
        const placeholderUrl = imageSize(
            Config.get('COMMON_APPS_URL_CONTENT_PLACEHOLDER'),
            this.props.ratio,
            this.props.width,
            this.props.method
        );

        const url = await PlaceholderManager.fetch(placeholderUrl);
        if (this.ready) {
            this.setState({ url });
        }
    }

    componentWillUnmount() {
        this.ready = false;
    }

    render() {
        if (this.state.url) {
            return <ImageStyle {...this.props} is="img" src={this.state.url} />;
        }
        return null;
    }
}

Placeholder.propTypes = {
    ratio: PropTypes.string.isRequired,
    width: PropTypes.number,
    method: PropTypes.string,
};

import styled from 'styled-components/macro';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

const gridSystem = props => {
    const auto = '1fr ';
    switch (typeof props.columns) {
        case 'number':
            return auto.repeat(props.columns);
        case 'object':
            return props.columns.map(col => {
                return auto.repeat(col);
            });
        default:
            return null;
    }
};

export default styled(Block).attrs(props => ({
    display: props.grid || 'grid',
    gridGap: props.gridGap || 1,
    gridTemplateColumns: gridSystem(props),
    p: 1,
    pb: 6,
}))``;

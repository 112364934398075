import styled from 'styled-components/macro';
import { keyframes } from 'styled-components/macro';

/* COMPONENTS */
const BounceAnimation = keyframes`
    0% { margin-bottom: 0; }
    50% { margin-bottom: 15px }
    100% { margin-bottom: 0 }
`;

export const DotWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vh;
`;

export const Dot = styled.div`
    background-color: #ccc;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    animation: ${BounceAnimation} 0.5s linear infinite;
    animation-delay: ${props => props.delay};
`;

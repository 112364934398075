import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import vodafoneEOT from './vodafone-regular-webfont-webfont.eot';
import vodafoneSVG from './vodafone-regular-webfont-webfont.svg';
import vodafoneTTF from './vodafone-regular-webfont-webfont.ttf';
import vodafoneWOFF from './vodafone-regular-webfont-webfont.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'vodafone_rgregular';
        font-display: auto;
        src: url(${staticPrefix + vodafoneWOFF}) format('woff'),
            url(${staticPrefix + vodafoneTTF}) format('truetype'),   
            url(${staticPrefix + vodafoneTTF}),
            url(${staticPrefix +
                vodafoneEOT}) format('embedded-opentype'),        
            url(${staticPrefix + vodafoneSVG}) format('svg');
            font-weight: normal;
            font-style: normal;
    }
`;

import React from 'react';
import PropTypes from 'prop-types';
import Config from '@docomodigital/js-config';

/* STYLES */
import {
    BoxPosition,
    GenericFlex,
    BoxEarth,
    GradientEarth,
    BoxEarthOrbit,
    BoxBalloon,
    BoxEarthOrbitTwo,
    BoxBalloonTwo,
    Clouds,
    BigCloud,
    SmallCloud,
} from './LoaderStyle';

const Loader = ({ error, timedOut, retry, isLoading, pastDelay, ...props }) => {
    if (props.error || props.timedOut) {
        return <div>Error!</div>;
    }
    return (
        <BoxPosition>
            <GenericFlex>
                <BoxEarth
                    style={{
                        backgroundImage: `url(${Config.get('TERRA_LOADER')})`,
                    }}
                >
                    <GradientEarth
                        style={{
                            backgroundImage: `url(${Config.get(
                                'TERRA_GRADIENT_LOADER'
                            )})`,
                        }}
                    >
                        {' '}
                    </GradientEarth>
                </BoxEarth>

                <BoxEarthOrbit>
                    <BoxBalloon
                        style={{
                            backgroundImage: `url(${Config.get(
                                'BALLOON_YELLOW'
                            )})`,
                        }}
                    >
                        {' '}
                    </BoxBalloon>
                </BoxEarthOrbit>

                <BoxEarthOrbitTwo>
                    <BoxBalloonTwo
                        style={{
                            backgroundImage: `url(${Config.get(
                                'BALLOON_PINK'
                            )})`,
                        }}
                    >
                        {' '}
                    </BoxBalloonTwo>
                </BoxEarthOrbitTwo>

                <Clouds>
                    <BigCloud
                        style={{
                            backgroundImage: `url(${Config.get('CLOUD_BIG')})`,
                        }}
                    >
                        {' '}
                    </BigCloud>
                    <SmallCloud
                        style={{
                            backgroundImage: `url(${Config.get(
                                'CLOUD_SMALL'
                            )})`,
                        }}
                    >
                        {' '}
                    </SmallCloud>
                </Clouds>
            </GenericFlex>
        </BoxPosition>
    );
};

Loader.propTypes = {
    theme: PropTypes.object,
    type: PropTypes.oneOf(['page', 'element']),
    error: PropTypes.bool,
    timedOut: PropTypes.bool,
};

Loader.defaultProps = {
    is: 'div',
    type: 'element',
};

export default Loader;

import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';

/* COMPONENTS */
import Image from 'components/Image/Image.jsx';

const Badge = styled(Image).attrs(props => ({
    shape: props.shape || themeGet('badgeShape')(props),
    border: props.border || 2,
    borderColor: props.borderColor || 'w',
}))``;

Badge.displayName = 'Badge';

Badge.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    shape: PropTypes.string,
    border: PropTypes.number,
    borderColor: PropTypes.string,
};

export default Badge;

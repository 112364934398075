import styled from 'styled-components/macro';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

export default styled(Block).attrs(props => ({
    // border: 2,
    // borderColor: 'w',
    m: props.m || 2,
    borderRadius: props.borderRadius || 1,
    maxWidth: props.maxWidth || 480,
}))`
    position: relative;
    /*max-height:290px;*/
    margin: auto;
`;

import React, { PureComponent } from 'react';
import Dictionary from '@docomodigital/js-dictionary';
import { Helmet } from 'react-helmet';
import { withTheme } from 'styled-components/macro';
import Config from '@docomodigital/js-config';
/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Card from 'components/Card/Card.jsx';
import Carousel from 'components/Carousel/Carousel.jsx';
import Cover from 'components/Cover/Cover.jsx';
import Container from 'components/Container/Container.jsx';
import List from 'components/List/List.jsx';
import Text from 'components/Text/Text.jsx';

class MixedListHighlights extends PureComponent {
    constructor(props) {
        super(props);

        // set title
        const customTitle = Dictionary.get(
            `SEO_WEBAPP_SITE_TYPE1_TITLE_${props.match.params.container.toUpperCase()}`
        );
        this.title =
            customTitle ||
            Dictionary.get('SEO_WEBAPP_SITE_TYPE1_TITLE_DEFAULT');

        this.imageSizes = Config.get('IMG_SIZES');
        // set description
        const customDescription = Dictionary.get(
            `SEO_WEBAPP_SITE_TYPE1_DESCRIPTION_${props.match.params.container.toUpperCase()}`
        );
        this.description =
            customDescription ||
            Dictionary.get('SEO_WEBAPP_SITE_TYPE1_DESCRIPTION_DEFAULT');
    }

    render() {
        this.carouselSettings = {
            arrows: true,
            autoplay: false,
            dots: true,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: this.props.theme.breakpoints[0],
                    settings: { slidesToShow: 1, arrows: false },
                },
            ],
            // slidesToShow: 1
        };

        // get path component without asking the API
        const urlSplit = this.props.match.url.split('/');
        let urlPrefix = null;
        if (urlSplit.length >= 2) {
            if (urlSplit[1].length === 2) {
                /* this is a country
                /az/${worldName}/mix/video/${item.id}
                */
                urlPrefix = `${urlSplit[1]}/${urlSplit[2]}`;
            } else {
                /* no country
                /${worldName}/mix/video/${item.id}
                */
                urlPrefix = `${urlSplit[1]}`;
            }
        }

        const highlightMixed = this.props.mixed.map((item, index) => (
            <Cover
                key={index}
                id={item.id}
                link={item.url_zoom}
                title={item.title}
                imageProps={{
                    src: item.images.cover.ratio_1_4,
                    ratio: 'video',
                    width: this.imageSizes.cover.large,
                    alt: item.title,
                }}
                container={item.container}
                contentType={item.format}
                fromPage="MixedListHighlights"
            />
        ));

        const videoCategories = this.props.categories.map((item, index) => (
            <Card
                key={index}
                id={item.id}
                link={`/${urlPrefix}/mix/video/${item.id}`}
                title={item.title}
                imageProps={{
                    src: item.images.cover.ratio_1_4,
                    ratio: 'video',
                    width: this.imageSizes.cover.medium,
                    alt: item.title,
                }}
                container={item.container}
                borderRadius={[1]}
                p={1}
                fromPage="MixedListCategory"
                eventName="Category_click"
            />
        ));

        const highlightGames = this.props.games.map((item, index) => (
            <Card
                key={index}
                id={item.id}
                link={item.url_zoom}
                title={item.title}
                imageProps={{
                    src:
                        item.images.icon && item.images.icon.ratio_1
                            ? item.images.icon.ratio_1
                            : item.images.cover.ratio_1,
                    ratio: 'games',
                    width: this.imageSizes.cover.medium,
                    alt: item.title,
                }}
                container={item.container}
                contentType={item.format}
                borderRadius={[1]}
                p={1}
                fromPage="MixedListHighlights"
                eventName="Content_click"
            />
        ));

        return [
            <Helmet key="helmet">
                <title>{this.title}</title>
                <meta name="description" content={this.description} />
            </Helmet>,
            <Block key="template" {...this.props}>
                <Container>
                    {/* VIDEO SELECTION */}

                    {videoCategories.length ? (
                        <Block
                            bg="bgBox1"
                            p={[0, 1, 2]}
                            borderBottom="1px solid"
                            borderColor="w"
                            boxShadow={1}
                        >
                            <Container maxWidth={0}>
                                <List columns={[2, 3, 4, 4]}>
                                    {videoCategories}
                                </List>
                            </Container>
                        </Block>
                    ) : null}

                    {/* GAMES SELECTION */}

                    {highlightGames.length ? (
                        <Block bg="bgBox2" p={[0, 1, 2]}>
                            <Text
                                is="h2"
                                fontSize={[3, 3]}
                                mt={2}
                                mb={1}
                                textAlign="center"
                                uppercase
                                ellipsis
                            >
                                <Text
                                    is="span"
                                    display="inline-block"
                                    bg="transparent"
                                    borderRadius={2}
                                    height={6}
                                    boxShadow={[3, 4]}
                                    pr={[13]}
                                >
                                    {Dictionary.get('WEBAPP_DDC_TITLE2_PART1')}
                                </Text>
                                <Text
                                    is="span"
                                    display="inline-block"
                                    bg="transparent"
                                    borderRadius={2}
                                    height={6}
                                    boxShadow={[3, 4]}
                                    color="secondaryFontTitleHome"
                                >
                                    {Dictionary.get('WEBAPP_DDC_TITLE2_PART2')}
                                </Text>
                            </Text>
                            <Container maxWidth={0}>
                                <List columns={[2, 3, 4, 4]}>
                                    {highlightGames}
                                </List>
                            </Container>
                        </Block>
                    ) : null}
                </Container>
            </Block>,
        ];
    }
}

export default withTheme(MixedListHighlights);

import React, { Children, PureComponent } from 'react';
import debounce from 'lodash.debounce';
import styled from 'styled-components/macro';
import Arrow from './Arrow';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

const SwipeListWrapper = styled(Block).attrs(props => ({
    swipe: props.swipe || true,
}))`
    min-width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    padding-bottom: 5px;

    &::-webkit-scrollbar {
        display: none;
        background: transparent;
    }

    @-moz-document url-prefix() {
        & {
            overflow-x: hidden;
        }
    }
`;

const ContainerSwipe = styled(Block)`
    overflow: hidden;
`;

const calculateVisibility = container => {
    const containerWidth = container.getBoundingClientRect().width;
    const rect = container.firstChild.getBoundingClientRect();
    const maxVisibleItems = containerWidth / rect.width;
    return {
        singleItemWidth: rect.width,
        maxVisibleItems,
    };
};

export default class SwipeList extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            scrollProps: {
                top: 0,
                left: 0,
                behavior: 'smooth',
                hasPrev: false,
                hasNext: true,
            },
            singleItemWidth: 310,
            listWidth: 0,
            maxWidth: 310,
            showArrow: false,
        };
        this.swipeContainerRef = React.createRef();
        this.detectOptimized = debounce(this.detectResize, 150);
        this.scrollOptimized = debounce(this.handleScroll, 150);
    }

    detectResize = () => {
        if (!this.swipeContainerRef.current) {
            return;
        }
        const { singleItemWidth, maxVisibleItems } = calculateVisibility(
            this.swipeContainerRef.current
        );
        const childrenLength = Children.toArray(this.props.children).length;

        this.setState({
            singleItemWidth,
            maxWidth: (childrenLength - maxVisibleItems) * singleItemWidth,
            showArrow: childrenLength > Math.floor(maxVisibleItems),
        });
    };

    componentDidMount() {
        this.detectResize();
        window.addEventListener('resize', this.detectOptimized);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.detectResize();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.detectOptimized);
    }

    swipeLeft = () => {
        const offsetX =
            this.state.scrollProps.left - this.state.singleItemWidth;
        const scrollProps = Object.assign({}, this.state.scrollProps, {
            left: offsetX < 0 ? 0 : offsetX,
        });
        this.setState({ scrollProps }, () => {
            this.swipeContainerRef.current.scroll(this.state.scrollProps);
        });
    };

    swipeRight = () => {
        const offsetX =
            this.state.scrollProps.left + this.state.singleItemWidth;
        let left = 0;
        if (offsetX >= this.state.maxWidth) {
            left = this.state.maxWidth;
        } else {
            left = offsetX;
        }
        const scrollProps = Object.assign({}, this.state.scrollProps, { left });
        this.setState({ scrollProps }, () => {
            this.swipeContainerRef.current.scroll(this.state.scrollProps);
        });
    };

    handleScroll = () => {
        if (!this.swipeContainerRef.current) {
            return;
        }
        const windowLength = window.innerWidth;
        const scrollContainer = this.swipeContainerRef.current.scrollWidth;
        const containerPosition = this.swipeContainerRef.current.scrollLeft;
        const distanceToEnd =
            scrollContainer - (containerPosition + windowLength);

        if (containerPosition === 0) {
            this.showButtonPrevNext(false, true);
        } else if (distanceToEnd <= 0) {
            this.showButtonPrevNext(true, false);
        } else {
            this.showButtonPrevNext(true, true);
        }
    };

    showButtonPrevNext(showPrev, showNext) {
        const scrollProps = Object.assign({}, this.state.scrollProps, {
            hasPrev: showPrev,
            hasNext: showNext,
        });
        this.setState({ scrollProps });
    }

    render() {
        const { showArrow, scrollProps } = this.state;
        const showLeft = showArrow && scrollProps.hasPrev;
        const showRight = showArrow && scrollProps.hasNext;

        return (
            <ContainerSwipe {...this.props} onScroll={this.scrollOptimized}>
                {showLeft && <Arrow onClick={this.swipeLeft} direction="sx" />}
                {showRight && (
                    <Arrow onClick={this.swipeRight} direction="dx" />
                )}

                <SwipeListWrapper ref={this.swipeContainerRef}>
                    {this.props.children}
                </SwipeListWrapper>
            </ContainerSwipe>
        );
    }
}

export const loadScript = url =>
    new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.async = true;
        script.src = url;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });

export const loadCSS = url =>
    new Promise((resolve, reject) => {
        const link = document.createElement('link');
        link.href = url;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.onload = resolve;
        link.onerror = reject;
        document.head.appendChild(link);
    });

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

/* STYLE */
import CarouselStyle from './CarouselStyle';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Icon from 'components/Icon/Icon.jsx';
import Text from 'components/Text/Text.jsx';

/* ARROWS */
const ArrowPrev = arrowProps => {
    return (
        <Icon
            content="arrow_sx"
            onClick={arrowProps.onClick}
            color={arrowProps.color}
            position="absolute"
            left="-28px"
            top="45%"
            fontSize={[4, 4, 2]}
        />
    );
};
const ArrowNext = arrowProps => {
    return (
        <Icon
            content="arrow_dx"
            onClick={arrowProps.onClick}
            color={arrowProps.color}
            position="absolute"
            right="-28px"
            top="45%"
            fontSize={[4, 4, 2]}
        />
    );
};

class Carousel extends PureComponent {
    constructor(props) {
        super(props);
        this.settings = Object.assign(
            {
                infinite: this.props.children.length > 2,
                speed: 500,
                slidesToScroll: 1,
                swipe: true,
                prevArrow: <ArrowPrev color={this.props.arrowsColor} />,
                nextArrow: <ArrowNext color={this.props.arrowsColor} />,
            },
            props.settings
        );
    }

    render() {
        return (
            <CarouselStyle {...this.props}>
                {this.props.title && (
                    <Block
                        position="absolute"
                        left="0"
                        right="0"
                        top="-8px"
                        m="auto"
                        textAlign="center"
                        zIndex="90"
                    >
                        <Text
                            is="h1"
                            bg="w"
                            borderRadius={3}
                            p={0}
                            border={4}
                            pl={10}
                            pr={10}
                            display="inline"
                        >
                            {this.props.title}
                        </Text>
                    </Block>
                )}

                <Block
                    position="relative"
                    borderColor="w"
                    border={[2, 3, 3]}
                    borderRadius={1}
                >
                    <Slider {...this.settings}>{this.props.children}</Slider>
                </Block>
            </CarouselStyle>
        );
    }
}

Carousel.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
    title: PropTypes.string,
    arrowsColor: PropTypes.string,
};

Carousel.defaultProps = {
    is: 'div',
    arrowsColor: 'w',
};

export default Carousel;

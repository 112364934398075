import styled from 'styled-components/macro';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

const Root = styled(Block).attrs(props => ({
    fontFamily: props.fontFamily || 'root',
}))`
    display: table;
    table-layout: fixed;
    height: 100vh;
    width: 100%;
`;

Root.displayName = 'Root';

export default Root;

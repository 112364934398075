import styled from 'styled-components/macro';
import { keyframes } from 'styled-components/macro';

/* COMPONENTS */

const rotate = keyframes`
    0% {background-position: 0 0;}
    100% {background-position: 630px 0;}
`;

const spinright = keyframes`
    100% {transform:rotate(360deg);}
`;

const balloon = keyframes`
    100% {transform:rotate(-360deg);}
`;

const move = keyframes`
    0%   {right: 20%;}
    100%  {right: 25%;}
`;

const move_2 = keyframes`
    0%   {left: 20%;}
    100%  {right: 25%;}
`;

export const BoxPosition = styled.div`
    position: relative;
    background: linear-gradient(rgb(91, 203, 245), rgb(255, 255, 255));
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
`;

export const GenericFlex = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BoxEarth = styled.div`
    position:relative;
    width:150px;
    height:150px;
    border-radius: 50%;
    transform-style: preserve-3d;
    box-shadow: 0px 0px 5px 7px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-position:0 0;
    background-repeat:repeat;
    background-size:630px;
    transition: transform 200ms linear;
    animation: ${rotate}  4s linear infinite;
    top:50%;
    left:50%;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        height: 150px;
        content: '';
        opacity: .2;
        border-radius: 50%;
        overflow: hidden;
    }
    &:after
        position: absolute;
        top: 0;
        left: 0;
        width: 150px;
        height: 150px;
        content: '';
        border-radius: 50%;
        overflow: hidden; 
`;

export const GradientEarth = styled.div`
    background-repeat: no-repeat;
    position: absolute;
    background-size: contain;
    width: 163px;
    height: 156px;
    left: -1%;
    top: -2%;
`;

export const BoxEarthOrbit = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    height: 200px;
    width: 200px;
    margin-left: -100px;
    margin-top: -97px;
    border-width: 2px;
    border-color: white;
    border-radius: 50%;
    animation: ${spinright} 4s linear infinite;
`;

export const BoxBalloon = styled.div`
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 80px;
    left: 100%;
    margin-left: -25px;
    margin-top: -25px;
    animation: ${balloon} 4s linear infinite;
`;

export const BoxEarthOrbitTwo = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    height: 240px;
    width: 240px;
    margin-left: -120px;
    margin-top: -120px;
    border-width: 2px;
    border-color: white;
    border-radius: 50%;
    animation: ${spinright} 6s linear infinite;
`;

export const BoxBalloonTwo = styled.div`
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -25px;
    margin-top: -25px;
    animation: ${balloon} 6s linear infinite;
`;

export const Clouds = styled.div`
    position: absolute;
    width: 283px;
    height: 97px;
    overflow: hidden;
`;

export const BigCloud = styled.div`
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    width: 66px;
    height: 33px;
    top: 20px;
    right: 23%;
    animation: ${move} 1s infinite;
    animation-direction: alternate;
`;

export const SmallCloud = styled.div`
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    width: 50px;
    height: 33px;
    bottom: 0px;
    left: 23%;
    overflow: hidden;
    animation: ${move_2} 1s infinite;
    animation-direction: alternate;
`;

import Analytics from '@docomodigital/js-analytics';
import Browser from '@docomodigital/js-browser';
import Config from '@docomodigital/js-config';
import Logger from '@docomodigital/js-logger';
import NewtonAdapter from 'newton-adapter';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';

/* COMPONENTS */
import Container from 'components/Container/Container.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Loader from 'components/Loader/Loader.jsx';
import Navbar from 'components/Navbar/Navbar.jsx';
import Root from 'components/Root/Root.jsx';

/* LIBRARIES */
import fetcher from 'lib/fetcher';
import parseApiParams from 'lib/parseApiParams';

/* PAGES */
import * as Pages from './Pages';

class Base extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compileConfig: [],
            compileData: [],
            compileDataLoaded: false,
            className: [],
        };
        this.loadMoreLock = false;
        this.getApiConfiguration = this.getApiConfiguration.bind(this);
        this.callApi = this.callApi.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.trackPageView = this.trackPageView.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    setClassName(props) {
        /* SET CLASS NAME FOR BODY TAG */
        const className = [
            props.routeConfig.key.replace('_', '-').toLowerCase(),
        ];
        Object.keys(props.match.params).forEach(key => {
            className[className.length] = `${key}-${props.match.params[key]}`;
        });
        this.setState({ className });
    }

    getApiConfiguration(props) {
        return new Promise((resolve, reject) => {
            const apiConfig =
                (props.routeConfig &&
                    props.routeConfig.api &&
                    props.routeConfig.api.compileTime) ||
                [];
            const apiConfigParsed = apiConfig.map(api => {
                /* CHANGE: use external library */
                /* PARSE API PARAMS */
                const params = parseApiParams(api.params, props.match.params);

                /* RETURNS PARSED API CONFIGURATION */
                return {
                    url: api.url,
                    params,
                    loadMore: params.page !== undefined,
                };
            });

            /* SET STATE WITH PARSED API CONFIGURATION */
            this.setState(
                {
                    compileConfig: apiConfigParsed,
                },
                resolve
            );
        });
    }

    callApi() {
        /* GET FETCHER FOR EVERY COMPILE API */
        const apiPromises = this.state.compileConfig.map(api =>
            fetcher.get(api.url, api.params)
        );

        /* WHEN ALL FETCHERS ARE COMPLETED, SET STATE WITH COMPILE DATA */
        Promise.all(apiPromises).then(compileData => {
            this.setState({
                compileData,
                compileDataLoaded: true,
            });
        });
    }

    loadMore(apiIndex) {
        /* RE-CALL API TO LOAD NEW ELEMENTS */
        // get configuration of api
        const apiConfig = this.state.compileConfig[apiIndex];
        if (apiConfig.loadMore && !this.loadMoreLock) {
            // block others loadMore calls
            this.loadMoreLock = true;
            // increment page param
            apiConfig.params.page++;
            // call api
            fetcher.get(apiConfig.url, apiConfig.params).then(newElements => {
                this.setState(
                    prevState => {
                        const newState = prevState;
                        // add new elements
                        newState.compileData[apiIndex] = newState.compileData[
                            apiIndex
                        ].concat(newElements);
                        // update incremented page param
                        newState.compileConfig[apiIndex].params.page =
                            apiConfig.params.page;
                        // update loadMore param: true only if new elements length is per_page param
                        newState.compileConfig[apiIndex].loadMore =
                            newElements.length === apiConfig.params.per_page;
                        // log new state
                        Logger.log('PAGE', 'loadMore', newState);
                        // set new state
                        return newState;
                    },
                    () => {
                        // unlock others loadMore calls
                        this.loadMoreLock = false;
                    }
                );
            });
        }
    }

    trackPageView(props) {
        Analytics.trackPage({
            page: props.location.pathname,
            title: props.component,
        });

        NewtonAdapter.trackPageview({
            properties: {
                page_path: props.location.pathname,
                page_title: props.component,
                // http_referrer: document.referrer,
                // previous_page: Browser.getPrevPage(),
                url: window.location.href,
            },
        });
    }

    scrollToTop() {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    componentDidMount() {
        /* CHANGE: window.location.href > window.location.pathname */
        Browser.shiftPage(window.location.pathname);

        if (Pages[this.props.component].preload) {
            Pages[this.props.component].preload();
        }

        this.setClassName(this.props);
        this.getApiConfiguration(this.props).then(this.callApi);
        this.trackPageView(this.props);
        this.scrollToTop();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.url !== this.props.match.url) {
            this.setState({ compileDataLoaded: false });
            this.setClassName(this.props);
            this.getApiConfiguration(this.props).then(this.callApi);
            this.trackPageView(this.props);
            this.scrollToTop();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.location.pathname !== this.props.location.pathname ||
            this.state !== nextState
        );
    }

    render() {
        if (!this.state.compileDataLoaded) {
            return <Loader type="page" />;
        } else {
            let errorScreen = this.state.compileData.length;
            this.state.compileData.forEach(compileDataElement => {
                if (Object.keys(compileDataElement).length > 0) {
                    errorScreen = false;
                }
            });

            const paramsForPage = {
                compileConfig: this.state.compileConfig,
                compileData: this.state.compileData,
                routeConfig: this.props.routeConfig,
            };

            const catchError = errorMessage => {
                // log error
                Logger.warn(
                    'PAGE',
                    this.props.component,
                    paramsForPage,
                    errorMessage,
                    'Redirect to error page'
                );
                // capture with Sentry
                if (Config.get('SENTRY_ENABLE')) {
                    Raven.captureException(new Error(errorMessage));
                }
                // redirect to error page
                window.location.href = `${Config.get(
                    'URLMGR_ERROR'
                )}?error=404`;
            };

            if (
                errorScreen &&
                !paramsForPage.routeConfig.allowVoidResponse &&
                !Config.get('ALLOW_VOID_COMPILEDATA_API')
            ) {
                catchError('CompileData are empty');
                return null;
            } else if (!Pages[this.props.component]) {
                catchError(
                    `Page component '${this.props.component}' not exist`
                );
                return null;
            } else if (Pages[this.props.component]) {
                Logger.log('PAGE', this.props.component, paramsForPage);
                const classNameString =
                    this.state.className && this.state.className.length
                        ? this.state.className.join(' ')
                        : '';
                const PageComponent = withRouter(Pages[this.props.component]);

                // page component + navbar
                const page = (
                    <React.Fragment>
                        {this.props.routeConfig.elements &&
                            this.props.routeConfig.elements.navbar && (
                                <Navbar routeConfig={this.props.routeConfig} />
                            )}
                        <Container
                            data-mipqa={this.props.component}
                            hasMargins={
                                this.props.routeConfig.elements &&
                                this.props.routeConfig.elements.navbar
                            }
                        >
                            <PageComponent
                                {...paramsForPage}
                                loadMore={this.loadMore}
                            />
                        </Container>
                    </React.Fragment>
                );
                return (
                    <Root data-mipqa="base">
                        {/* (1/3) HELMET */}
                        <Helmet key="helmet">
                            <body className={classNameString}></body>
                        </Helmet>

                        {/* (2/3) PAGE COMPONENT */}
                        <React.Fragment key="component">{page}</React.Fragment>

                        {/* (3/3) FOOTER */}
                        {this.props.routeConfig.elements &&
                        this.props.routeConfig.elements.footer ===
                            false ? null : (
                            <Footer key="footer" />
                        )}
                    </Root>
                );
            } else {
                Logger.log('PAGE', this.props.component, paramsForPage);
                const pageError = `Page component '${this.props.component}' not exist, add it to pages/_base/Pages`;

                if (Config.get('SENTRY_ENABLE')) {
                    Raven.captureException(new Error(pageError));
                } else {
                    Logger.error(pageError);
                }

                return pageError;
            }
        }
    }
}

Base.propTypes = {
    component: PropTypes.string.isRequired,
    routeConfig: PropTypes.object.isRequired,
};

export default Base;

// export default withRouter(Base); // direct child of Route component, not needed to wrap with withRouter

import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import icomoonEOT from './icomoon-vodafone.eot';
import icomoonSVG from './icomoon-vodafone.svg';
import icomoonTTF from './icomoon-vodafone.ttf';
import icomoonWOFF from './icomoon-vodafone.woff';

export default createGlobalStyle`
    @font-face {
        font-family: 'icomoon-vodafone';
        font-display: auto;
        src: url(${staticPrefix + icomoonWOFF}) format('woff'),
            url(${staticPrefix + icomoonTTF}) format('truetype'),   
            url(${staticPrefix + icomoonEOT}),
            url(${staticPrefix +
                icomoonEOT}) format('embedded-opentype'),        
            url(${staticPrefix + icomoonSVG}) format('svg');
    }

    .icon-back:before {
        content: "\\e900";
    }
    .icon-search:before {
        content: "\\e901";
    }
    .icon-account:before {
        content: "\\e902";
    }
    .icon-close:before {
        content: "\\e903";
        vertical-align:super;
    }
    .icon-arrow_sx:before {
        content: "\\e904";
    }
    .icon-arrow_down:before {
        content: "\\e905";
    }
    .icon-arrow_dx:before {
        content: "\\e906";
    }
    
    

`;

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Analytics from '@docomodigital/js-analytics';
import NewtonAdapter from 'newton-adapter';

/* STYLE */
import CardStyle from './CardStyle';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Text from 'components/Text/Text.jsx';
import Image from 'components/Image/Image.jsx';
import Badge from 'components/Badge/Badge.jsx';

/* LIBRARIES */
import contentTypeIcon from 'lib/formatter/contentTypeIcon';

const Card = props => {
    const _onClick = () => {
        /* TRACKING */
        // const eventName = 'Content_click';
        Analytics.trackEvent({
            category: props.fromPage,
            action: props.eventName,
            label: props.title,
        });

        let properties = {
            content_ID: props.id,
            content_title: props.title,
        };
        if (props.contentType) {
            properties.content_type = props.contentType;
        }

        NewtonAdapter.trackEvent({
            name: props.eventName,
            properties,
        });

        /* RUN OTHER METHODS */
        if (props.onClick) {
            props.onClick(props);
        }
    };

    return (
        <CardStyle {...props} to={props.link} onClick={_onClick}>
            <Image lazy={true} {...props.imageProps} borderRadius={1} />

            {props.contentType && (
                <Image
                    src={contentTypeIcon(props.contentType)}
                    alt={props.contentType}
                    height={7}
                    position="absolute"
                    zIndex="99"
                    top="0px"
                    right="5px"
                    filter="drop-shadow(0px 1px 3px rgba(0,0,0,0.3))"
                />
            )}

            {props.selectedContainer && (
                <Badge
                    src={props.selectedContainer.images.badge}
                    alt={props.selectedContainer.name}
                    maxWidth={[2, 4]}
                    position="absolute"
                    left="0"
                    right="0"
                    bottom={[65, 82]}
                    m="auto"
                />
            )}

            <Block height={[75, 95]}>
                <Text
                    is="h3"
                    fontSize={[1, 1, 1]}
                    ellipsis={2}
                    textAlign="center"
                    pt={[2, 7]}
                >
                    {props.title}
                </Text>
            </Block>
        </CardStyle>
    );
};

Card.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
    link: PropTypes.string.isRequired,
    container: PropTypes.string,
    contentType: PropTypes.string,
    title: PropTypes.string.isRequired,
    id: PropTypes.string,
    fromPage: PropTypes.string,
    eventName: PropTypes.string,
    imageProps: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        ratio: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        method: PropTypes.string,
        lazy: PropTypes.bool,
    }),
};

Card.defaultProps = {
    is: Link,
};

const mapStateToProps = (state, props) => ({
    selectedContainer: state.containers.filter(
        container => container.container === props.container
    )[0],
});

export default connect(mapStateToProps)(Card);

import Config from '@docomodigital/js-config';
import Logger from '@docomodigital/js-logger';
import React, { PureComponent } from 'react';

/* COMPONENTS */
import Loader from 'components/Loader/Loader.jsx';

class GoToError extends PureComponent {
    componentDidMount() {
        let currentPath = window.location.pathname;
        let externalPaths = Config.get('EXTERNAL_PAGES_PATH') || [];
        let matchRegex = false;
        Logger.log('GoToError', currentPath, externalPaths);

        for (let i = 0; i < externalPaths.length && !matchRegex; i++) {
            let externalPathRegex = new RegExp(externalPaths[i], 'gi');
            matchRegex = externalPathRegex.test(currentPath);
        }

        if (matchRegex) {
            Logger.log('GoToError', 'reload');
            //alert('GoToError: reload '+ currentPath);
            window.location.reload();
        } else {
            Logger.log('GoToError', 'go to error');
            //alert('GoToError: go to error');
            window.location.href = Config.get('URLMGR_ERROR')+'?gte=1';
        }
    }

    render() {
        return <Loader type="page" />;
    }
}

export default GoToError;

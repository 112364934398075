import React, { PureComponent } from 'react';
import Dictionary from '@docomodigital/js-dictionary';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Config from '@docomodigital/js-config';
/* COMPONENTS */
import Banner from 'components/Banner/Banner.jsx';
import Card from 'components/Card/Card.jsx';
import Container from 'components/Container/Container.jsx';
import List from 'components/List/List.jsx';

class SimpleList extends PureComponent {
    constructor(props) {
        super(props);

        // set title
        const customTitle = Dictionary.get(
            `SEO_WEBAPP_SITE_TYPE1_TITLE_${props.match.params.container.toUpperCase()}`
        );
        this.title =
            customTitle ||
            Dictionary.get('SEO_WEBAPP_SITE_TYPE1_TITLE_DEFAULT');
        this.imageSizes = Config.get('IMG_SIZES');
        // set description
        const customDescription = Dictionary.get(
            `SEO_WEBAPP_SITE_TYPE1_DESCRIPTION_${props.match.params.container.toUpperCase()}`
        );
        this.description =
            customDescription ||
            Dictionary.get('SEO_WEBAPP_SITE_TYPE1_DESCRIPTION_DEFAULT');
    }

    render() {
        const items = this.props.compileData[0].map((item, index) => {
            return (
                <Card
                    key={index}
                    id={item.id}
                    link={item.url_zoom}
                    title={item.title}
                    imageProps={{
                        src: item.images.cover.ratio_1_4,
                        ratio: 'mix',
                        width: this.imageSizes.cover.medium,
                        alt: item.title,
                    }}
                    container={item.container}
                    contentType={item.format}
                    borderRadius={[1]}
                    p={1}
                    fromPage="SimpleList"
                    eventName="Content_click"
                />
            );
        });

        return [
            <Helmet key="helmet">
                <title>{this.title}</title>
                <meta name="description" content={this.description} />
            </Helmet>,
            <React.Fragment key="template">
                <Banner
                    backArrowColor={
                        this.props.selectedContainer.theme.primaryColor
                    }
                    linearGradient={[
                        this.props.selectedContainer.theme.gradientTop,
                        this.props.selectedContainer.theme.gradientBottom,
                    ]}
                    src={this.props.selectedContainer.images.cover}
                    alt={this.props.selectedContainer.name}
                    boxShadow={1}
                    mb={4}
                />

                <Container maxWidth={0}>
                    <List columns={[2, 3, 4, 4]}>{items}</List>
                </Container>
            </React.Fragment>,
        ];
    }
}

const mapStateToProps = (state, props) => ({
    selectedContainer: state.containers.filter(
        container => container.container === props.match.params.container
    )[0],
});

export default connect(mapStateToProps)(SimpleList);

import { linearGradient } from 'props-to-styled';
import styled from 'styled-components/macro';
import {
    display,
    borders,
    borderRadius,
    space,
    color,
    fontSize,
    minWidth,
    minHeight,
    width,
    textAlign,
    boxShadow,
    borderColor,
    position,
    justifyContent,
    alignItems,
} from 'styled-system';

import linearGradientAttribute from 'styles/attributes/linearGradient';

/* COMPONENTS */
import Base from 'components/Base';

export default styled(Base).attrs(props => ({
    border: props.border || 2,
    borderColor: props.borderColor || 'w',
    borderRadius: props.borderRadius || 1,
    minWidth: props.minWidth || '150px',
    minHeight: props.minHeight || '35px',
    linearGradient: linearGradientAttribute(props),
    boxShadow: props.boxShadow,
    position: props.position,
}))`
    /* styled-system */
    ${display};
    ${borders};
    ${borderRadius};
    ${space};
    ${color};
    ${fontSize};
    ${minWidth};
    ${minHeight};
    ${width};
    ${textAlign};
    ${linearGradient};
    ${boxShadow};
    ${borderColor};
    ${position};
    ${alignItems};
    ${justifyContent};
`;

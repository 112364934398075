import Config from '@docomodigital/js-config';

export default (url, ratio, width = 1024, method = 'crop') => {
    const [ratioWidth, ratioHeight] =
        Config.get('IMG_RATIO') && Config.get('IMG_RATIO')[ratio]
            ? Config.get('IMG_RATIO')[ratio]
            : [4, 3];
    const height = Math.round((width / ratioWidth) * ratioHeight);
    return url
        .replace('[WSIZE]', width)
        .replace('[HSIZE]', height)
        .replace('[METHOD]', method);
};

/*
// example of usage:
imagesize:'cover_item':'big'
imagesize:'illustration':'medium':aspect	
imagesize:'item':'small':item.images.cover.ratio_1_aspect
imagesize:'slider':'small'

export default(url, imgtype, imgdim, aspect) => {
    var imgSizeConf = Config.get('IMG_SIZES'),
        ratio = Config.get('USED_RATIO') ? Config.get('USED_RATIO').split(':') : [4, 3], // fallback, perchè sai mai.
        imgW = parseInt(imgSizeConf[imgtype][imgdim], 10),
        imgH = Math.round((imgW / parseInt(ratio[0], 10)) * parseInt(ratio[1], 10));

    if(url && imgtype) {
        if(aspect === 'vertical') {
            url = url.replace('[WSIZE]', imgH);
            url = url.replace('[HSIZE]', imgW);
            url = url.replace('[METHOD]', 'cropin');
        } else {
            url = url.replace('[WSIZE]', imgW);
            url = url.replace('[HSIZE]', imgH);
            url = url.replace('[METHOD]', 'crop');
        }
    }

    return url;
}; 
*/

import { themeGet } from 'styled-system';

export default props => {
    if (typeof props.filter !== 'undefined') {
        if (themeGet(`filters.${props.filter}`)(props)) {
            return themeGet(`filters.${props.filter}`)(props);
        } else {
            return props.filter;
        }
    } else {
        return null;
    }
};

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Icon from 'components/Icon/Icon.jsx';

const ArrowButton = styled(Block).attrs(props => ({
    position: 'absolute',
    display: ['none', 'flex'],
    fontSize: [4, 4, 2],
}))`
    cursor: pointer;
    align-items: center;
    padding-top: 10px;
    height: 100%;
    z-index: 99;
`;

const Arrow = props => {
    const left = props.direction === 'sx' ? '1px' : undefined;
    const right = props.direction === 'dx' ? '1px' : undefined;

    return (
        <ArrowButton onClick={props.onClick} right={right} left={left}>
            <Icon
                content={`arrow_${props.direction}`}
                pt={1}
                pr={1}
                pb={0}
                pl={1}
                bg="bgArrows"
            ></Icon>
        </ArrowButton>
    );
};

Arrow.propTypes = {
    direction: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default memo(Arrow);

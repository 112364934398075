import React from 'react';
import Icomoon from 'fonts/icomoon/icomoon';
import IcomoonVodafone from 'fonts/icomoon-vodafone/icomoon-vodafone';
import IcomoonUbuntu from 'fonts/icomoon-ubuntu/icomoon-ubuntu';
import IcomoonVodacom from 'fonts/icomoon-vodacom/icomoon-vodacom';
import Montserrat from 'fonts/montserrat/montserrat';
import Oswald from 'fonts/oswald/oswald';
import Robotocondensed from 'fonts/robotocondensed/robotocondensed';
import Vodafonelt from 'fonts/vodafonelt/vodafonelt';
import Vodafone from 'fonts/vodafone-complete/vodafone';
import Tenorsans from 'fonts/tenorsans/tenorsans';
import Ubuntu from 'fonts/ubuntu/ubuntu';

const Fonts = () => {
    return [
        <Icomoon key="icomoon" />,
        <IcomoonVodafone key="icomoon-vodafone" />,
        <Montserrat key="montserrat" />,
        <Oswald key="oswald" />,
        <Robotocondensed key="robotocondensed" />,
        <Vodafonelt key="vodafonelt" />,
        <Vodafone key="vodafone" />,
        <Tenorsans key="tenorsans" />,
        <Ubuntu key="ubuntu" />,
        <IcomoonUbuntu key="icomoon-ubuntu" />,
        <IcomoonVodacom key="icomoon-vodacom" />,
    ];
};

export default Fonts;

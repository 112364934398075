import { fixed, float, linearGradient, swipe } from 'props-to-styled';
import styled from 'styled-components/macro';
import {
    verticalAlign,
    overflow,
    textAlign,
    position,
    top,
    right,
    bottom,
    left,
    space,
    color,
    fontFamily,
    boxShadow,
    display,
    borders,
    fontSize,
    borderColor,
    borderRadius,
    backgroundImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat,
    height,
    minHeight,
    maxHeight,
    width,
    minWidth,
    maxWidth,
    gridGap,
    gridTemplateColumns,
    zIndex,
    justifyContent,
    alignItems,
} from 'styled-system';
import linearGradientAttribute from 'styles/attributes/linearGradient';

/* COMPONENTS */
import Base from 'components/Base';

export default styled(Base).attrs(props => ({
    linearGradient: linearGradientAttribute(props),
}))`
    /* styled-system */
    ${verticalAlign};
    ${overflow};
    ${textAlign};
    ${fontSize};
    ${space};
    ${fontFamily};
    ${boxShadow};
    ${display};
    ${borders};
    ${borderColor};
    ${borderRadius};
    ${color};
    ${backgroundImage};
    ${backgroundSize};
    ${backgroundPosition};
    ${backgroundRepeat};
    ${height};
    ${minHeight};
    ${maxHeight};
    ${width};
    ${minWidth};
    ${maxWidth};
    ${gridGap};
    ${gridTemplateColumns};
    ${zIndex};
    ${position};
    ${top};
    ${right};
    ${bottom};
    ${left};
    ${alignItems};
    ${justifyContent};

    /* custom styles */
    ${fixed};
    ${swipe};
    ${float};
    ${linearGradient};
`;

/*
 *  action types
 */

export const SET_CONTAINERS = 'SET_CONTAINERS';

export const SET_CONNECTION = 'SET_CONNECTION';

/*
 *  action creators
 */

export const setContainers = value => ({
    type: SET_CONTAINERS,
    value,
});

export const setConnection = value => ({
    type: SET_CONNECTION,
    value,
});

import styled from 'styled-components/macro';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

const Container = styled(Block).attrs(props => ({
    mt:
        props.hasMargins &&
        (props.theme.containerMargin === 'top' ||
            props.theme.containerMargin === 'both')
            ? 9
            : null,
    mb:
        props.hasMargins &&
        (props.theme.containerMargin === 'bottom' ||
            props.theme.containerMargin === 'both')
            ? 3
            : null,
}))`
    margin-left: auto;
    margin-right: auto;
`;

export default Container;

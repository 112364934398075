import styled from 'styled-components/macro';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

export default styled(Block).attrs(props => ({
    bg: props.bg || 'w',
    boxShadow: props.boxShadow || 0,
}))`
    height: auto;
    text-align: center;
    position: relative;
`;

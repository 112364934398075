import styled from 'styled-components/macro';

export const BoxPosition = styled.div`
    font-family: 'webfontregular', Arial Narrow, sans-serif;
    position: relative;
    background: ${props => props.theme.bgSmartbanner || '#F5F5F5'};
    border-bottom: ${props => props.theme.borderSmartbanner || '0px'};
    width: 100%;
`;

export const GenericFlex = styled.div`
    display: flex;
    list-style-type: none;
    flex-flow: row wrap;
    z-index: 1000;
    max-width: 768px;
    margin: auto;
    padding: 5px 5px;
    background: #f5f5f5;
`;

export const InvisibleLink = styled.a`
    display: none;
`;

export const ListFlex = styled.div`
    width: auto;
    height: auto;
    text-align: center;
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    &:first-child {
        max-width: 50px;
    }
    &:nth-child(2) {
        max-width: 75px;
        align-items: center;
    }
    &:nth-child(3) {
        padding-left: 0px;
    }
`;

export const IconService = styled.span`
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    box-shadow: ${props => props.theme.boxShadowIconService || '0px'};
`;

export const IconClose = styled.div`
    background-color: ${props =>
        props.theme.colors.bgIconSmartbanner || 'transparent'};
    width: 30px;
    height: 30px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
`;

export const MessageTxt = styled.div`
    margin-top: 10px;
`;

export const TitleBanner = styled.h3`
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    font-size: 18px;
`;

export const TextBanner = styled.p`
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: left;
    &:nth-child(3) {
        display: none;
    }
    font-size: 15px;
    @media screen and (min-width: 768px) {
        font-size: 18px;
    }
`;

export const LinkDownload = styled.a`
    display: inline-block;
    text-align: center;
    cursor: pointer;
    background: ${props => props.theme.bgBtnSmartbanner || 'transparent'};
    border:  ${props => props.theme.colors.borderBtnSmartbanner};
    border-radius:${props => props.theme.borderRadiusBtnSmartbanner || '0px'};
    min-width: auto;
    min-height: auto;
    font-size: 20px;
    padding: ${props => props.theme.paddingBtnSmartbanner || '8px 5px'};
    color:  ${props => props.theme.txtBtnSmartbanner || '#000000'};
    box-shadow:${props => props.theme.boxShadowBtnSmartbanner || '0px'};
}
`;

import PropTypes from 'prop-types';
import { ellipsisPropTypes, uppercasePropTypes } from 'props-to-styled';

/* STYLE */
import TextStyle from './TextStyle';

const Text = TextStyle;

// class Text extends Component {
// 	render() {
// 		return (
// 			<TextStyle {...this.props}>
// 				{this.props.children}
// 			</TextStyle>
// 		);
// 	}
// }

Text.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
    ...ellipsisPropTypes,
    ...uppercasePropTypes,
};

Text.defaultProps = {
    is: 'p',
};

export default Text;

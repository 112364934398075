import { loadScript, loadCSS } from './_asyncLoad';

/**
 * Load configuration and Newton library at runtime
 * @export
 * @returns {Promise} - resolved with routing, config, dictionary
 */
export default ({ fetcher }) => {
    // Don't uncomment (and commit) this line, it's useful only for quick-temporary-only-local debug

    let prefix = process.env.REACT_APP_MOCK
        ? process.env.REACT_APP_MOCK_PREFIX
        : '';
    let suffix = process.env.REACT_APP_MOCK
        ? process.env.REACT_APP_MOCK_SUFFIX
        : '';

    //per far funzionare la lingua anche sul refresh, atrimenti non c'è il prefix e il router pensa che le rotte siano tutte sbagliate (WL-1847)    
    const configList = window.configList;
    const langs = configList.MULTILANGUAGE_LIST;
    langs.forEach(lang => {
        const singlelang = `/${lang.ID}/`
        if (window.location.href.includes(singlelang)) {
            prefix = `/${lang.ID}`
        }
    });

    //console.log('PROCESS ENV', process.env, 'prefix',prefix, 'suffix', suffix, `${prefix}/v01/config.getvars${suffix}`);

    const configPromise = fetcher.get(
        `${prefix}/v01/config.getvars${suffix}`,
        {}
    );
    const routingPromise = fetcher.get(
        `${prefix}/v01/config.getrouting${suffix}`,
        {}
    );
    const dictPromise = fetcher.get(
        `${prefix}/v01/dictionary.getlist${suffix}`,
        {}
    );

    let configResponse, routingResponse, dictResponse, themeResponse;
    return Promise.all([configPromise, routingPromise, dictPromise])
        .then(([configResp, routingResp, dictResp]) => {
            // save config, routing and dict
            configResponse = configResp;
            routingResponse = routingResp;
            dictResponse = dictResp;

            // load theme, if enabled
            if (configResponse.LOAD_REMOTE_THEME) {
                return fetcher.get(
                    `${prefix}/v01/fileconf.getmergedjson${suffix}`,
                    {}
                );
            } else return false;
        })
        .then(themeResp => {
            // save theme (undefined if not enabled)
            themeResponse = themeResp;

            // load Newton SDK
            return loadScript(configResponse.URLMGR_JS_NEWTON_SDK);
        })
        .then(() => {
            // load async less URL, if enabled
            if (configResponse.LOAD_ASYNC_LESS_URL) {
                loadCSS(configResponse.URLMGR_APP_LESS_URL);
            }
            return true;
        })
        .then(() => {
            // return config, routing, dict, theme
            return [
                configResponse,
                routingResponse,
                dictResponse,
                themeResponse,
            ];
        })
        .catch(error => {
            throw error;
        });
};

import React from 'react';
import { FadeIn } from 'animate-css-styled-components';
import PropTypes from 'prop-types';

/* STYLE */
import ListStyle from './ListStyle';

const List = props => (
    <FadeIn>
        <ListStyle {...props}>{props.children}</ListStyle>
    </FadeIn>
);

List.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
};

List.defaultProps = {
    is: 'div',
};

export default List;

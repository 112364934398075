import { createGlobalStyle } from 'styled-components';

import { staticPrefix } from 'lib/prefix';
import ubuntuEOT from './ubuntu-r-webfont.eot';
import ubuntuSVG from './ubuntu-r-webfont.svg';
import ubuntuTTF from './ubuntu-r-webfont.ttf';
import ubuntuWOFF from './ubuntu-r-webfont.woff';

export default createGlobalStyle`
    @font-face {
        font-family:'ubunturegular';
        font-display: auto;
        src: url(${staticPrefix + ubuntuWOFF}) format('woff'),
            url(${staticPrefix + ubuntuTTF}) format('truetype'),   
            url(${staticPrefix + ubuntuEOT}),
            url(${staticPrefix +
                ubuntuEOT}) format('embedded-opentype'),        
            url(${staticPrefix + ubuntuSVG}) format('svg');
        font-weight: normal;
        font-style: normal;    
    }
`;

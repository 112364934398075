import React, { PureComponent } from 'react';
import Config from '@docomodigital/js-config';
import Dictionary from '@docomodigital/js-dictionary';
import User, { withAccount } from '@productfe/react-user';

import Block from 'components/Block/Block.jsx';
import Button from 'components/Button/Button.jsx';
import Icon from 'components/Icon/Icon.jsx';
import Loader from 'components/Loader/Loader.jsx';
import Text from 'components/Text/Text.jsx';

class AccordionItem extends PureComponent {
    render() {
        return (
            <Block
                maxWidth={[0]}
                mt={[8]}
                mb={[8]}
                ml={[11]}
                mr={[11]}
                position="relative"
                p={[1]}
                borderBottom={[7]}
            >
                {/* <h3 onClick={this.props.onClick} dataindex={this.props.dataIndex}>{this.props.label}</h3> */}
                <Text
                    is="h3"
                    fontSize={[2, 7]}
                    onClick={this.props.onClick}
                    dataindex={this.props.dataIndex}
                >
                    {this.props.label}
                </Text>

                {/* <span className="icon-arrow_right" onClick={this.props.onClick} dataindex={this.props.dataIndex}/> */}
                <Icon
                    content={this.props.active ? 'arrow_down' : 'arrow_dx'}
                    top="0px"
                    color="colorArrowBack"
                    fontSize={[4, 2]}
                    p={[1]}
                    position="absolute"
                    right="0px"
                    onClick={this.props.onClick}
                    dataindex={this.props.dataIndex}
                />

                {/* <ul>
                    <li className={`account-section`}>
                        <div dangerouslySetInnerHTML={{ __html: this.props.body }}/>
                    </li>
                </ul> */}
                <Block
                    display={this.props.active ? 'block' : 'none'}
                    p={this.props.active ? '10px' : '0px'}
                    dangerouslySetInnerHTML={{ __html: this.props.body }}
                />
            </Block>
        );
    }
}

class Account extends PureComponent {
    render() {
        const showLogoutButton =
            User.isLogged() &&
            !Config.get('HIDE_LOGOUT_BUTTON') &&
            Config.get('ENABLE_NEWTON_USER');

        /*
            <div className="account30">
                <ul>
                    ...
                </ul>
            </div>
        */

        return this.props.isProcessing ? (
            <Loader />
        ) : (
            <React.Fragment>
                {this.props.sections.map((tabObject, i) => (
                    <AccordionItem
                        dataIndex={i}
                        onClick={this.props.openOnClick}
                        key={`item_${i}`}
                        label={tabObject.label}
                        body={tabObject.body}
                        active={this.props.activeIndex === i}
                    />
                ))}

                {showLogoutButton && (
                    <Button onClick={this.props.logout}>
                        {Dictionary.get('WEBAPP_UO30_LOGOUT')}
                    </Button>
                )}
            </React.Fragment>
        );
    }
}

export default withAccount(Account);

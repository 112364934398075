import React from 'react';
import PropTypes from 'prop-types';

/* STYLES */
import { DotWrapper, Dot } from './LoaderListStyle';

const LoaderList = ({ error, timedOut, ...props }) => {
    if (props.error || props.timedOut) {
        return <div>Error!</div>;
    }
    return (
        <DotWrapper>
            <Dot delay="0s" />
            <Dot delay=".1s" />
            <Dot delay=".2s" />
        </DotWrapper>
    );
};

LoaderList.propTypes = {
    theme: PropTypes.object,
    type: PropTypes.oneOf(['page', 'element']),
    error: PropTypes.bool,
    timedOut: PropTypes.bool,
};

LoaderList.defaultProps = {
    is: 'div',
    type: 'element',
};

export default LoaderList;

import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import Analytics from '@docomodigital/js-analytics';
import NewtonAdapter from 'newton-adapter';

/* STYLE */
import CoverStyle from './CoverStyle';

/* COMPONENTS */
// import Badge from 'components/Badge/Badge.jsx/Badge';
import Block from 'components/Block/Block.jsx';
import Text from 'components/Text/Text.jsx';
import Image from 'components/Image/Image.jsx';

/* LIBRARIES */
import contentTypeIcon from 'lib/formatter/contentTypeIcon';

const CoverWrapper = props => {
    const _onClick = () => {
        /* TRACKING */
        const eventName = 'Content_click';
        Analytics.trackEvent({
            category: props.fromPage,
            action: eventName,
            label: props.title,
        });
        NewtonAdapter.trackEvent({
            name: eventName,
            properties: {
                content_type: props.contentType,
                content_ID: props.id,
                content_title: props.title,
            },
        });

        /* RUN OTHER METHODS */
        if (props.onClick) {
            props.onClick(props);
        }
    };

    if (props.link) {
        return (
            <Link to={props.link} onClick={_onClick}>
                {props.children}
            </Link>
        );
    } else {
        return props.children;
    }
};

const Cover = props => {
    return (
        <CoverWrapper {...props}>
            <CoverStyle {...props}>
                <Block
                    overflow="hidden"
                    borderColor={['#fff', '#fff']}
                    border={[3, 2, 2]}
                    borderRadius={1}
                    ml={['0px', '5px', '10px']}
                    mr={['0px', '5px', '10px']}
                    position="relative"
                >
                    {props.contentType && (
                        <Image
                            src={contentTypeIcon(props.contentType)}
                            alt={props.contentType}
                            height={7}
                            position="absolute"
                            zIndex="101"
                            top="5px"
                            right="5px"
                            filter={[0]}
                        />
                    )}

                    <Image lazy={true} {...props.imageProps} borderRadius={1} />

                    {props.title && (
                        <Block
                            position="absolute"
                            left="0px"
                            height="40px"
                            right="0px"
                            bottom="-4px"
                            bg="bgMessage"
                        >
                            <Text
                                is="h3"
                                color="w"
                                fontSize={[1, 1]}
                                textAlign="center"
                                lineHeight="40px"
                            >
                                {props.title}
                            </Text>
                        </Block>
                    )}
                </Block>
            </CoverStyle>
        </CoverWrapper>
    );
};

Cover.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object,
    link: PropTypes.string,
    // imageSrc: PropTypes.string.isRequired,
    // imageAlt: PropTypes.string.isRequired,
    // imagePointerFix: PropTypes.bool,
    contentType: PropTypes.string,
    title: PropTypes.string,
    id: PropTypes.string,
    fromPage: PropTypes.string,
    imageProps: PropTypes.shape({
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        ratio: PropTypes.string,
        width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        method: PropTypes.string,
        pointerFix: PropTypes.bool,
        lazy: PropTypes.bool,
    }),
};

Cover.defaultProps = {
    is: 'div',
};

export default Cover;

import Config from '@docomodigital/js-config';
import Dict from '@docomodigital/js-dictionary';
import Logger from '@docomodigital/js-logger';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

/* STYLE */
import FooterStyle from './FooterStyle';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';
import Container from 'components/Container/Container.jsx';
import Image from 'components/Image/Image.jsx';
import Text from 'components/Text/Text.jsx';

/* LIBRARIES */
import fetcher from 'lib/fetcher';

class Footer extends PureComponent {
    constructor(props) {
        super(props);

        this.mounted = true;

        this.state = {
            footerLinks: [],
        };
    }

    componentDidMount() {
        const prefix = process.env.REACT_APP_MOCK
            ? process.env.REACT_APP_MOCK_PREFIX
            : '';
        const suffix = process.env.REACT_APP_MOCK
            ? process.env.REACT_APP_MOCK_SUFFIX
            : '';

        const footerAPI = process.env.REACT_APP_NODOCKER
            ? `${prefix}/v01/footer.getlist${suffix}`
            : Config.get('API_RUNTIME.FOOTER_GETLIST.url');

        fetcher.get(footerAPI, {}).then(footerLinks => {
            Logger.log('FOOTER', footerLinks);

            if (typeof footerLinks === 'object' && footerLinks.length) {
                if (this.mounted) {
                    this.setState({ footerLinks });
                }
            } else {
                const errorMessage =
                    'Footer response is not an array or is void';
                Raven.captureException(new Error(errorMessage));
                Logger.error(errorMessage);
            }
        });
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    render() {
        /* LINKS */
        const links = this.state.footerLinks.map((link, index) => {
            if (link.is_external) {
                return (
                    <a key={index} href={link.url} data-mipqa="footer-link">
                        <Text
                            is="p"
                            color="textFooterColor"
                            textAlign="center"
                            m={1}
                            pt={13}
                            pb={13}
                        >
                            {link.label}
                        </Text>
                    </a>
                );
            } else {
                return (
                    <Link key={index} to={link.url} data-mipqa="footer-link">
                        <Text
                            is="p"
                            color="textFooterColor"
                            textAlign="center"
                            m={1}
                            pt={13}
                            pb={13}
                        >
                            {link.label}
                        </Text>
                    </Link>
                );
            }
        });

        /* MULTILANGUAGE */
        const changeLanguage = link => {
            if (link !== '') {
                window.location.href = link;
            }
        };
        const languages = (Config.get('MULTILANGUAGE_LIST') || []).map(
            (language, index) => {
                const link = language.IS_SELECTED
                    ? ''
                    : language._LANGURL_ABSOLUTE;

                return (
                    <Text
                        key={index}
                        is="p"
                        p={[1]}
                        display="inline"
                        color="textFooterColor"
                        ml={[1]}
                        mr={[1]}
                        border={language.IS_SELECTED ? '1px solid' : ''}
                        borderColor="borderMultiLanguage"
                        onClick={() => {
                            changeLanguage(link);
                        }}
                    >
                        {language.LABEL}
                    </Text>
                );
            }
        );

        return (
            <FooterStyle {...this.props}>
                <Container data-mipqa="footer">
                    <Block
                        borderColor="borderColorFooter"
                        mt={0}
                        ml={[14]}
                        mr={[14]}
                        borderBottom={0}
                        p={[12]}
                    >
                        <Image
                            src={Config.get('LOGO_FOOTER')}
                            alt="{Dictionary.get('SEO_WEBAPP_TITLE_SERVICE')}"
                            maxWidth={9}
                            m="auto"
                            p={1}
                        />
                    </Block>

                    {Config.get('SHOW_PROMOTIONAL_FOOTER') ? (
                        <Block
                            height="50px"
                            bg="footerBgSecond"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Image
                                src={Config.get('LOGO_BG_RED')}
                                alt="{Dictionary.get('SEO_WEBAPP_TITLE_SERVICE')}"
                                width="135px"
                                height="auto"
                            />
                        </Block>
                    ) : null}

                    {this.state.footerLinks.length > 0 ? (
                        <Block data-mipqa="footer-links" pb={6}>
                            {links}
                        </Block>
                    ) : null}

                    {Config.get('SHOW_MULTIPLE_LANGUAGE') ? (
                        <Block pb={[2]}>
                            <Text pt={[4]} pb={[2]} color="textFooterColor">
                                {Dict.get('WEBAPP_CHANGE_LANGUAGE')}
                            </Text>
                            {languages}
                        </Block>
                    ) : null}
                </Container>
            </FooterStyle>
        );
    }
}

Footer.propTypes = {
    theme: PropTypes.object,
};

Footer.defaultProps = {
    is: 'div',
};

export default Footer;

import Config from '@docomodigital/js-config';

export default customContentType => {
    switch (customContentType) {
        case 'video':
            return Config.get('BADGE_VIDEO');
        case 'game':
        default:
            return Config.get('BADGE_GAME');
    }
};

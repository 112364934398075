import Config from '@docomodigital/js-config';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import Dictionary from '@docomodigital/js-dictionary';
import User from '@productfe/react-user';

/* STYLE */
import NavbarStyle, { PromotionalStyle } from './NavbarStyle';

/* COMPONENTS */
import Container from 'components/Container/Container.jsx';
import Icon from 'components/Icon/Icon.jsx';
import Image from 'components/Image/Image.jsx';
import Text from 'components/Text/Text.jsx';
import Block from 'components/Block/Block.jsx';

class Navbar extends PureComponent {
    render() {
        let accountLink = Config.get('URLMGR_SETTINGS_UNLOGGED');
        if (
            User.isLogged() &&
            !(
                Config.get('ENABLE_NEWTON_USER') &&
                User.getProperty('fake') === 'fake'
            )
        ) {
            accountLink = Config.get('ROUTING.ROUTER_SETTINGS_LOGGED.path');
        } else if (
            Config.get('ADD_DOMAIN_TO_ACCOUNT_LINK') &&
            Config.get('DEST_DOMAIN')
        ) {
            accountLink = Config.get('DEST_DOMAIN') + accountLink;
        }

        return (
            <React.Fragment>
                <NavbarStyle {...this.props} data-mipqa="navbar">
                    <Container maxWidth={0} position="relative">
                        <Link
                            to={Config.get('ROUTING.ROUTER_HOME.path')}
                            data-mipqa="navbar-logo"
                        >
                            <Image
                                src={Config.get('LOGO_SERVICE')}
                                alt="{Dictionary.get('SEO_WEBAPP_TITLE_SERVICE')}"
                                maxWidth={8}
                                ml={4}
                                float="left"
                                height={[5]}
                                pt={0}
                                pb={0}
                            />
                        </Link>
                        {Config.get('SHOW_OPTIONAL_LOGO') ? (
                            <Link
                                to={Config.get('ROUTING.ROUTER_HOME.path')}
                                data-mipqa="navbar-logo"
                            >
                                <Image
                                    src={Config.get('LOGO_SERVICE_OPTIONAL')}
                                    alt="{Dictionary.get('SEO_WEBAPP_TITLE_SERVICE')}"
                                    maxWidth={8}
                                    position="absolute"
                                    zIndex="99"
                                    top="50%"
                                    right="0px"
                                    left="0"
                                    m="auto"
                                    height={[5]}
                                />
                            </Link>
                        ) : null}



                        <Block
                            mr={[4, 4, 2]}
                            float="right"
                            data-mipqa="navbar-account"
                        >
                            <Link to={accountLink}>
                                <Icon
                                    content="account"
                                    fontSize={7}
                                    color="fontIcoColor"
                                    float="left"
                                    lineHeight={1}
                                    data-mipqa="account-link"
                                />
                            </Link>
                            <Link to={accountLink}>
                                <Text
                                    is="span"
                                    data-mipqa="account-link-text"
                                    color="primary"
                                    verticalAlign="sub"
                                    ml={0}
                                    fontSize={[0, 1]}
                                    textAlign="left"
                                    display={['none', 'none', 'inline-block']}
                                    lineHeight={1}
                                >
                                    {Dictionary.get('WEBAPP_ACCOUNT')}
                                </Text>
                            </Link>
                        </Block>
                        {/* Disable search route
                        <Link to={Config.get('ROUTING.ROUTER_SEARCH.path')} data-mipqa="navbar-search">
                            <Block  mr={[4, 4, 2]} float="right">
                                <Icon content='search' fontSize={7} float="left"
                                    color={ this.props.routeConfig.key === 'ROUTER_SEARCH' ? 'tertiary' : 'secondary' } lineHeight={1} />
                                <Text is='span' color="primary" verticalAlign="sub" ml={0} fontSize={[0,4]} textAlign='left' display={['none', 'none', 'inline-block']}  lineHeight={1}  >
                                    SEARCH
                                </Text>
                            </Block>
                        </Link> */}
                    </Container>
                </NavbarStyle>

                {Config.get('SHOW_PROMOTIONAL_NAVBAR') ? (
                    <PromotionalStyle
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                        boxShadow={[1]}
                    >
                        <Image
                            src={Config.get('LOGO_BG_RED')}
                            alt="{Dictionary.get('SEO_WEBAPP_TITLE_SERVICE')}"
                            width="auto"
                            height={[4]}
                        />
                    </PromotionalStyle>
                ) : null}
            </React.Fragment>
        );
    }
}

Navbar.propTypes = {
    theme: PropTypes.object,
    routeConfig: PropTypes.object.isRequired,
};

Navbar.defaultProps = {
    is: 'div',
};

export default Navbar;

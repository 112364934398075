import Config from '@docomodigital/js-config';
import Logger from '@docomodigital/js-logger';
import VideoPlayerLibrary from '@docomodigital/videoplayer';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import NewtonAdapter from 'newton-adapter';

/* COMPONENTS */
import Block from 'components/Block/Block.jsx';

/* LIBRARIES */
import fetcher from 'lib/fetcher';
import parseApiParams from 'lib/parseApiParams';

const videoPlayerNodeID = 'videoplayer';

class VideoPlayer extends PureComponent {
    constructor(props) {
        super(props);

        this.mounted = true;

        this.state = {
            playerId: null,
        };
    }

    componentDidMount() {
        /* GET VIDEO URL */
        const isDesktop = Config.get('UA') === 'DESKTOP';
        let quality;
        if (isDesktop || this.props.isWifi) {
            quality = 'HD';
        } else {
            quality = 'MD';
        }

        const videoStreamingApi = Config.get('API_RUNTIME')
            .VIDEO_STREAMING_INFO;
        const videoStreamingParams = parseApiParams(
            Object.assign(
                {},
                videoStreamingApi.params,
                {
                    id: this.props.itemID,
                    videoid: this.props.videoID,
                    access_token: NewtonAdapter.getUserToken(),
                    force_quality: quality,
                },
                this.props.catalogID && { customer_id: this.props.catalogID }
            )
        );

        fetcher
            .get(videoStreamingApi.url, videoStreamingParams, {
                credentials: 'include',
            })
            .then(response => {
                let videoUrl;
                let mimeType;
                if (response && response.clips) {
                    videoUrl =
                        response.clips[Object.keys(response.clips)[0]].url; // Always get the first clip, filtered by force_quality param (usually only 1 clip, the desired one)
                    mimeType =
                        response.clips[Object.keys(response.clips)[0]].mimeType;
                }
                if (videoUrl && videoUrl !== '' && videoUrl !== false) {
                    this.createPlayer(videoUrl, mimeType);
                } else {
                    Logger.error(
                        'VIDEOPLAYER',
                        'Bvideoplayer call do not return a valid URL'
                    );
                }
            })
            .catch(error => {
                Logger.error(
                    'VIDEOPLAYER',
                    'Bvideoplayer API call is failed',
                    error.message
                );
            });
    }

    createPlayer(videoUrl, mimeType) {
        if (!this.mounted) {
            return;
        }
        const player = VideoPlayerLibrary.createPlayer({
            domNode: document.getElementById(videoPlayerNodeID),
            url: videoUrl,
            mime: mimeType,
            provider: 'newflowplayer',
            providerOptions: {
                autoplay: Config.get('VIDEO_PLAYERS_AUTOPLAY'),
                token: Config.get('FLOWPLAYER_TOKEN'),
                poster: this.props.poster,
            },
            events: {
                firstPlay: () => {
                    Logger.log('VIDEOPLAYER', 'firstPlay');
                    if (this.props.onFirstPlay) {
                        this.props.onFirstPlay();
                    }
                },
            },
        });
        this.setState({ playerId: player.id });
    }

    componentWillUnmount() {
        this.mounted = false;
        if (this.state.playerId) {
            VideoPlayerLibrary.clear(this.state.playerId);
        }
    }

    render() {
        return <Block id={videoPlayerNodeID}></Block>;
    }
}

VideoPlayer.propTypes = {
    itemID: PropTypes.string.isRequired,
    videoID: PropTypes.string.isRequired,
    catalogID: PropTypes.string,
    poster: PropTypes.string.isRequired,
    onFirstPlay: PropTypes.func,
    isWifi: PropTypes.bool.isRequired,
};

export default VideoPlayer;

import Config from '@docomodigital/js-config';
import { TokenGenerator } from '@docomodigital/js-fetcher';
import Base64 from 'base-64';

export default ({ fetcher }) => {
    if (Config.get('JWT_ENABLED')) {
        // **** TMPL_VAR doesn't work in .js files ****
        // let clientId = "<TMPL_VAR NAME=S2S_LAPIS_API_CLIENT_ID>";
        // if(clientId === "" || clientId.indexOf('TMPL_VAR') !== -1){
        //     clientId = process.env.REACT_APP_JWT_CLIENT_ID;
        // }
        // let clientSecret = "<TMPL_VAR NAME=S2S_LAPIS_API_CLIENT_SECRET>";
        // if(clientSecret === "" || clientSecret.indexOf('TMPL_VAR') !== -1){
        //     clientSecret = process.env.REACT_APP_JWT_CLIENT_SECRET;
        // }

        let clientId = process.env.REACT_APP_JWT_CLIENT_ID;
        clientId = atob(Base64.encode(clientId));
        let clientSecret = process.env.REACT_APP_JWT_CLIENT_SECRET;
        clientSecret = atob(Base64.encode(clientSecret));

        const tokenGenerator = new TokenGenerator({
            url: Config.get('MOA_API_AUTHORIZE_TO_LAPIS'),
            clientId,
            clientSecret,
        });
        fetcher.setTokenGenerator(tokenGenerator);
    }
};

import arrayBufferToBase64 from './arrayBufferToBase64';
import fetcher from './fetcher';

export class PlaceholderManager {
    static cache = {};

    static lastPromise = null;

    static fetch(url) {
        if (this.cache[url]) {
            return this.cache[url];
        }
        if (!this.lastPromise) {
            this.lastPromise = fetcher
                .get(url, {}, { credentials: 'omit' }, false)
                .then(res => res.arrayBuffer())
                .then(
                    res => `data:image/jpg;base64,${arrayBufferToBase64(res)}`
                )
                .then(res => {
                    this.lastPromise = null;
                    this.cache[url] = res;
                    return res;
                })
                .catch(err => {
                    console.log(err);
                });
        }
        return this.lastPromise;
    }
}
